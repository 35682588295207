/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * API Gateway
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 *
 * @export
 * @interface AccountDataDto
 */
export interface AccountDataDto {
    /**
     *
     * @type {string}
     * @memberof AccountDataDto
     */
    userId?: string;
    /**
     *
     * @type {string}
     * @memberof AccountDataDto
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof AccountDataDto
     */
    surname?: string;
    /**
     *
     * @type {string}
     * @memberof AccountDataDto
     */
    middleName?: string;
    /**
     *
     * @type {string}
     * @memberof AccountDataDto
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof AccountDataDto
     */
    role?: string;
    /**
     *
     * @type {boolean}
     * @memberof AccountDataDto
     */
    isExternalAuth?: boolean;
    /**
     *
     * @type {string}
     * @memberof AccountDataDto
     */
    githubId?: string;
    /**
     *
     * @type {string}
     * @memberof AccountDataDto
     */
    bio?: string;
    /**
     *
     * @type {string}
     * @memberof AccountDataDto
     */
    companyName?: string;
}
/**
 *
 * @export
 * @interface AddAnswerForQuestionDto
 */
export interface AddAnswerForQuestionDto {
    /**
     *
     * @type {number}
     * @memberof AddAnswerForQuestionDto
     */
    questionId?: number;
    /**
     *
     * @type {string}
     * @memberof AddAnswerForQuestionDto
     */
    answer?: string;
}
/**
 *
 * @export
 * @interface AddTaskQuestionDto
 */
export interface AddTaskQuestionDto {
    /**
     *
     * @type {number}
     * @memberof AddTaskQuestionDto
     */
    taskId?: number;
    /**
     *
     * @type {string}
     * @memberof AddTaskQuestionDto
     */
    text?: string;
    /**
     *
     * @type {boolean}
     * @memberof AddTaskQuestionDto
     */
    isPrivate?: boolean;
}
/**
 *
 * @export
 * @interface AdvancedCourseStatisticsViewModel
 */
export interface AdvancedCourseStatisticsViewModel {
    /**
     *
     * @type {CoursePreview}
     * @memberof AdvancedCourseStatisticsViewModel
     */
    course?: CoursePreview;
    /**
     *
     * @type {Array<HomeworkViewModel>}
     * @memberof AdvancedCourseStatisticsViewModel
     */
    homeworks?: Array<HomeworkViewModel>;
    /**
     *
     * @type {Array<StatisticsCourseMatesModel>}
     * @memberof AdvancedCourseStatisticsViewModel
     */
    studentStatistics?: Array<StatisticsCourseMatesModel>;
    /**
     *
     * @type {Array<StatisticsCourseMeasureSolutionModel>}
     * @memberof AdvancedCourseStatisticsViewModel
     */
    averageStudentSolutions?: Array<StatisticsCourseMeasureSolutionModel>;
    /**
     *
     * @type {Array<StatisticsCourseMeasureSolutionModel>}
     * @memberof AdvancedCourseStatisticsViewModel
     */
    bestStudentSolutions?: Array<StatisticsCourseMeasureSolutionModel>;
}
/**
 *
 * @export
 * @interface BooleanResult
 */
export interface BooleanResult {
    /**
     *
     * @type {boolean}
     * @memberof BooleanResult
     */
    value?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof BooleanResult
     */
    succeeded?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof BooleanResult
     */
    errors?: Array<string>;
}
/**
 *
 * @export
 * @interface CategorizedNotifications
 */
export interface CategorizedNotifications {
    /**
     *
     * @type {CategoryState}
     * @memberof CategorizedNotifications
     */
    category?: CategoryState;
    /**
     *
     * @type {Array<NotificationViewModel>}
     * @memberof CategorizedNotifications
     */
    seenNotifications?: Array<NotificationViewModel>;
    /**
     *
     * @type {Array<NotificationViewModel>}
     * @memberof CategorizedNotifications
     */
    notSeenNotifications?: Array<NotificationViewModel>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum CategoryState {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2,
    NUMBER_3 = <any> 3
}
/**
 *
 * @export
 * @interface CourseEvents
 */
export interface CourseEvents {
    /**
     *
     * @type {number}
     * @memberof CourseEvents
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof CourseEvents
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof CourseEvents
     */
    groupName?: string;
    /**
     *
     * @type {boolean}
     * @memberof CourseEvents
     */
    isCompleted?: boolean;
    /**
     *
     * @type {number}
     * @memberof CourseEvents
     */
    newStudentsCount?: number;
}
/**
 *
 * @export
 * @interface CoursePreview
 */
export interface CoursePreview {
    /**
     *
     * @type {number}
     * @memberof CoursePreview
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof CoursePreview
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof CoursePreview
     */
    groupName?: string;
    /**
     *
     * @type {boolean}
     * @memberof CoursePreview
     */
    isCompleted?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof CoursePreview
     */
    mentorIds?: Array<string>;
    /**
     *
     * @type {number}
     * @memberof CoursePreview
     */
    taskId?: number;
}
/**
 *
 * @export
 * @interface CoursePreviewView
 */
export interface CoursePreviewView {
    /**
     *
     * @type {number}
     * @memberof CoursePreviewView
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof CoursePreviewView
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof CoursePreviewView
     */
    groupName?: string;
    /**
     *
     * @type {boolean}
     * @memberof CoursePreviewView
     */
    isCompleted?: boolean;
    /**
     *
     * @type {Array<AccountDataDto>}
     * @memberof CoursePreviewView
     */
    mentors?: Array<AccountDataDto>;
    /**
     *
     * @type {number}
     * @memberof CoursePreviewView
     */
    taskId?: number;
}
/**
 *
 * @export
 * @interface CourseViewModel
 */
export interface CourseViewModel {
    /**
     *
     * @type {number}
     * @memberof CourseViewModel
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof CourseViewModel
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof CourseViewModel
     */
    groupName?: string;
    /**
     *
     * @type {boolean}
     * @memberof CourseViewModel
     */
    isOpen?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof CourseViewModel
     */
    isCompleted?: boolean;
    /**
     *
     * @type {Array<AccountDataDto>}
     * @memberof CourseViewModel
     */
    mentors?: Array<AccountDataDto>;
    /**
     *
     * @type {Array<AccountDataDto>}
     * @memberof CourseViewModel
     */
    acceptedStudents?: Array<AccountDataDto>;
    /**
     *
     * @type {Array<AccountDataDto>}
     * @memberof CourseViewModel
     */
    newStudents?: Array<AccountDataDto>;
    /**
     *
     * @type {Array<HomeworkViewModel>}
     * @memberof CourseViewModel
     */
    homeworks?: Array<HomeworkViewModel>;
}
/**
 *
 * @export
 * @interface CreateCourseViewModel
 */
export interface CreateCourseViewModel {
    /**
     *
     * @type {string}
     * @memberof CreateCourseViewModel
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof CreateCourseViewModel
     */
    groupName?: string;
    /**
     *
     * @type {boolean}
     * @memberof CreateCourseViewModel
     */
    isOpen: boolean;
}
/**
 *
 * @export
 * @interface CreateGroupViewModel
 */
export interface CreateGroupViewModel {
    /**
     *
     * @type {string}
     * @memberof CreateGroupViewModel
     */
    name?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof CreateGroupViewModel
     */
    groupMatesIds: Array<string>;
    /**
     *
     * @type {number}
     * @memberof CreateGroupViewModel
     */
    courseId: number;
}
/**
 *
 * @export
 * @interface CreateHomeworkViewModel
 */
export interface CreateHomeworkViewModel {
    /**
     *
     * @type {string}
     * @memberof CreateHomeworkViewModel
     */
    title: string;
    /**
     *
     * @type {string}
     * @memberof CreateHomeworkViewModel
     */
    description?: string;
    /**
     *
     * @type {boolean}
     * @memberof CreateHomeworkViewModel
     */
    hasDeadline?: boolean;
    /**
     *
     * @type {Date}
     * @memberof CreateHomeworkViewModel
     */
    deadlineDate?: Date;
    /**
     *
     * @type {boolean}
     * @memberof CreateHomeworkViewModel
     */
    isDeadlineStrict?: boolean;
    /**
     *
     * @type {Date}
     * @memberof CreateHomeworkViewModel
     */
    publicationDate?: Date;
    /**
     *
     * @type {boolean}
     * @memberof CreateHomeworkViewModel
     */
    isGroupWork?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof CreateHomeworkViewModel
     */
    tags?: Array<string>;
    /**
     *
     * @type {Array<CreateTaskViewModel>}
     * @memberof CreateHomeworkViewModel
     */
    tasks?: Array<CreateTaskViewModel>;
}
/**
 *
 * @export
 * @interface CreateTaskViewModel
 */
export interface CreateTaskViewModel {
    /**
     *
     * @type {string}
     * @memberof CreateTaskViewModel
     */
    title: string;
    /**
     *
     * @type {string}
     * @memberof CreateTaskViewModel
     */
    description?: string;
    /**
     *
     * @type {boolean}
     * @memberof CreateTaskViewModel
     */
    hasDeadline?: boolean;
    /**
     *
     * @type {Date}
     * @memberof CreateTaskViewModel
     */
    deadlineDate?: Date;
    /**
     *
     * @type {boolean}
     * @memberof CreateTaskViewModel
     */
    isDeadlineStrict?: boolean;
    /**
     *
     * @type {Date}
     * @memberof CreateTaskViewModel
     */
    publicationDate?: Date;
    /**
     *
     * @type {number}
     * @memberof CreateTaskViewModel
     */
    maxRating: number;
}
/**
 *
 * @export
 * @interface EditAccountViewModel
 */
export interface EditAccountViewModel {
    /**
     *
     * @type {string}
     * @memberof EditAccountViewModel
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof EditAccountViewModel
     */
    surname: string;
    /**
     *
     * @type {string}
     * @memberof EditAccountViewModel
     */
    middleName?: string;
    /**
     *
     * @type {string}
     * @memberof EditAccountViewModel
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof EditAccountViewModel
     */
    bio?: string;
    /**
     *
     * @type {string}
     * @memberof EditAccountViewModel
     */
    companyName?: string;
}
/**
 *
 * @export
 * @interface EditMentorWorkspaceDTO
 */
export interface EditMentorWorkspaceDTO {
    /**
     *
     * @type {Array<string>}
     * @memberof EditMentorWorkspaceDTO
     */
    studentIds?: Array<string>;
    /**
     *
     * @type {Array<number>}
     * @memberof EditMentorWorkspaceDTO
     */
    homeworkIds?: Array<number>;
}
/**
 *
 * @export
 * @interface ExpertDataDTO
 */
export interface ExpertDataDTO {
    /**
     *
     * @type {string}
     * @memberof ExpertDataDTO
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ExpertDataDTO
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof ExpertDataDTO
     */
    surname?: string;
    /**
     *
     * @type {string}
     * @memberof ExpertDataDTO
     */
    middleName?: string;
    /**
     *
     * @type {string}
     * @memberof ExpertDataDTO
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof ExpertDataDTO
     */
    bio?: string;
    /**
     *
     * @type {string}
     * @memberof ExpertDataDTO
     */
    companyName?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ExpertDataDTO
     */
    tags?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof ExpertDataDTO
     */
    lecturerId?: string;
}
/**
 *
 * @export
 * @interface FileInfoDTO
 */
export interface FileInfoDTO {
    /**
     *
     * @type {string}
     * @memberof FileInfoDTO
     */
    name?: string;
    /**
     *
     * @type {number}
     * @memberof FileInfoDTO
     */
    size?: number;
    /**
     *
     * @type {string}
     * @memberof FileInfoDTO
     */
    key?: string;
    /**
     *
     * @type {number}
     * @memberof FileInfoDTO
     */
    homeworkId?: number;
}
/**
 *
 * @export
 * @interface FilesUploadBody
 */
export interface FilesUploadBody {
    /**
     *
     * @type {number}
     * @memberof FilesUploadBody
     */
    courseId?: number;
    /**
     *
     * @type {number}
     * @memberof FilesUploadBody
     */
    homeworkId?: number;
    /**
     *
     * @type {Blob}
     * @memberof FilesUploadBody
     */
    file: Blob;
}
/**
 *
 * @export
 * @interface GetSolutionModel
 */
export interface GetSolutionModel {
    /**
     *
     * @type {Date}
     * @memberof GetSolutionModel
     */
    ratingDate?: Date;
    /**
     *
     * @type {number}
     * @memberof GetSolutionModel
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GetSolutionModel
     */
    githubUrl?: string;
    /**
     *
     * @type {string}
     * @memberof GetSolutionModel
     */
    comment?: string;
    /**
     *
     * @type {SolutionState}
     * @memberof GetSolutionModel
     */
    state?: SolutionState;
    /**
     *
     * @type {number}
     * @memberof GetSolutionModel
     */
    rating?: number;
    /**
     *
     * @type {string}
     * @memberof GetSolutionModel
     */
    studentId?: string;
    /**
     *
     * @type {number}
     * @memberof GetSolutionModel
     */
    taskId?: number;
    /**
     *
     * @type {Date}
     * @memberof GetSolutionModel
     */
    publicationDate?: Date;
    /**
     *
     * @type {string}
     * @memberof GetSolutionModel
     */
    lecturerComment?: string;
    /**
     *
     * @type {Array<AccountDataDto>}
     * @memberof GetSolutionModel
     */
    groupMates?: Array<AccountDataDto>;
    /**
     *
     * @type {AccountDataDto}
     * @memberof GetSolutionModel
     */
    lecturer?: AccountDataDto;
}
/**
 *
 * @export
 * @interface GetTaskQuestionDto
 */
export interface GetTaskQuestionDto {
    /**
     *
     * @type {number}
     * @memberof GetTaskQuestionDto
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof GetTaskQuestionDto
     */
    studentId?: string;
    /**
     *
     * @type {string}
     * @memberof GetTaskQuestionDto
     */
    text?: string;
    /**
     *
     * @type {boolean}
     * @memberof GetTaskQuestionDto
     */
    isPrivate?: boolean;
    /**
     *
     * @type {string}
     * @memberof GetTaskQuestionDto
     */
    answer?: string;
    /**
     *
     * @type {string}
     * @memberof GetTaskQuestionDto
     */
    lecturerId?: string;
}
/**
 *
 * @export
 * @interface GithubCredentials
 */
export interface GithubCredentials {
    /**
     *
     * @type {string}
     * @memberof GithubCredentials
     */
    githubId?: string;
}
/**
 *
 * @export
 * @interface GroupMateViewModel
 */
export interface GroupMateViewModel {
    /**
     *
     * @type {string}
     * @memberof GroupMateViewModel
     */
    studentId?: string;
}
/**
 *
 * @export
 * @interface GroupViewModel
 */
export interface GroupViewModel {
    /**
     *
     * @type {number}
     * @memberof GroupViewModel
     */
    id?: number;
    /**
     *
     * @type {Array<string>}
     * @memberof GroupViewModel
     */
    studentsIds?: Array<string>;
}
/**
 *
 * @export
 * @interface HomeworkSolutionsStats
 */
export interface HomeworkSolutionsStats {
    /**
     *
     * @type {string}
     * @memberof HomeworkSolutionsStats
     */
    homeworkTitle?: string;
    /**
     *
     * @type {Array<TaskSolutionsStats>}
     * @memberof HomeworkSolutionsStats
     */
    statsForTasks?: Array<TaskSolutionsStats>;
}
/**
 *
 * @export
 * @interface HomeworkTaskForEditingViewModel
 */
export interface HomeworkTaskForEditingViewModel {
    /**
     *
     * @type {HomeworkTaskViewModel}
     * @memberof HomeworkTaskForEditingViewModel
     */
    task?: HomeworkTaskViewModel;
    /**
     *
     * @type {HomeworkViewModel}
     * @memberof HomeworkTaskForEditingViewModel
     */
    homework?: HomeworkViewModel;
}
/**
 *
 * @export
 * @interface HomeworkTaskViewModel
 */
export interface HomeworkTaskViewModel {
    /**
     *
     * @type {number}
     * @memberof HomeworkTaskViewModel
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof HomeworkTaskViewModel
     */
    title?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof HomeworkTaskViewModel
     */
    tags?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof HomeworkTaskViewModel
     */
    description?: string;
    /**
     *
     * @type {number}
     * @memberof HomeworkTaskViewModel
     */
    maxRating?: number;
    /**
     *
     * @type {boolean}
     * @memberof HomeworkTaskViewModel
     */
    hasDeadline?: boolean;
    /**
     *
     * @type {Date}
     * @memberof HomeworkTaskViewModel
     */
    deadlineDate?: Date;
    /**
     *
     * @type {boolean}
     * @memberof HomeworkTaskViewModel
     */
    isDeadlineStrict?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof HomeworkTaskViewModel
     */
    canSendSolution?: boolean;
    /**
     *
     * @type {Date}
     * @memberof HomeworkTaskViewModel
     */
    publicationDate?: Date;
    /**
     *
     * @type {number}
     * @memberof HomeworkTaskViewModel
     */
    homeworkId?: number;
    /**
     *
     * @type {boolean}
     * @memberof HomeworkTaskViewModel
     */
    isGroupWork?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof HomeworkTaskViewModel
     */
    isDeferred?: boolean;
}
/**
 *
 * @export
 * @interface HomeworkUserTaskSolutions
 */
export interface HomeworkUserTaskSolutions {
    /**
     *
     * @type {string}
     * @memberof HomeworkUserTaskSolutions
     */
    homeworkTitle?: string;
    /**
     *
     * @type {Array<UserTaskSolutions2>}
     * @memberof HomeworkUserTaskSolutions
     */
    studentSolutions?: Array<UserTaskSolutions2>;
}
/**
 *
 * @export
 * @interface HomeworkViewModel
 */
export interface HomeworkViewModel {
    /**
     *
     * @type {number}
     * @memberof HomeworkViewModel
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof HomeworkViewModel
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof HomeworkViewModel
     */
    description?: string;
    /**
     *
     * @type {boolean}
     * @memberof HomeworkViewModel
     */
    hasDeadline?: boolean;
    /**
     *
     * @type {Date}
     * @memberof HomeworkViewModel
     */
    deadlineDate?: Date;
    /**
     *
     * @type {boolean}
     * @memberof HomeworkViewModel
     */
    isDeadlineStrict?: boolean;
    /**
     *
     * @type {Date}
     * @memberof HomeworkViewModel
     */
    publicationDate?: Date;
    /**
     *
     * @type {number}
     * @memberof HomeworkViewModel
     */
    courseId?: number;
    /**
     *
     * @type {boolean}
     * @memberof HomeworkViewModel
     */
    isDeferred?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof HomeworkViewModel
     */
    isGroupWork?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof HomeworkViewModel
     */
    tags?: Array<string>;
    /**
     *
     * @type {Array<HomeworkTaskViewModel>}
     * @memberof HomeworkViewModel
     */
    tasks?: Array<HomeworkTaskViewModel>;
}
/**
 *
 * @export
 * @interface HomeworksGroupSolutionStats
 */
export interface HomeworksGroupSolutionStats {
    /**
     *
     * @type {string}
     * @memberof HomeworksGroupSolutionStats
     */
    groupTitle?: string;
    /**
     *
     * @type {Array<HomeworkSolutionsStats>}
     * @memberof HomeworksGroupSolutionStats
     */
    statsForHomeworks?: Array<HomeworkSolutionsStats>;
}
/**
 *
 * @export
 * @interface HomeworksGroupUserTaskSolutions
 */
export interface HomeworksGroupUserTaskSolutions {
    /**
     *
     * @type {string}
     * @memberof HomeworksGroupUserTaskSolutions
     */
    groupTitle?: string;
    /**
     *
     * @type {Array<HomeworkUserTaskSolutions>}
     * @memberof HomeworksGroupUserTaskSolutions
     */
    homeworkSolutions?: Array<HomeworkUserTaskSolutions>;
}
/**
 *
 * @export
 * @interface InviteExpertViewModel
 */
export interface InviteExpertViewModel {
    /**
     *
     * @type {string}
     * @memberof InviteExpertViewModel
     */
    userId?: string;
    /**
     *
     * @type {string}
     * @memberof InviteExpertViewModel
     */
    userEmail?: string;
    /**
     *
     * @type {number}
     * @memberof InviteExpertViewModel
     */
    courseId?: number;
    /**
     *
     * @type {Array<string>}
     * @memberof InviteExpertViewModel
     */
    studentIds?: Array<string>;
    /**
     *
     * @type {Array<number>}
     * @memberof InviteExpertViewModel
     */
    homeworkIds?: Array<number>;
}
/**
 *
 * @export
 * @interface InviteLecturerViewModel
 */
export interface InviteLecturerViewModel {
    /**
     *
     * @type {string}
     * @memberof InviteLecturerViewModel
     */
    email: string;
}
/**
 *
 * @export
 * @interface LoginViewModel
 */
export interface LoginViewModel {
    /**
     *
     * @type {string}
     * @memberof LoginViewModel
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof LoginViewModel
     */
    password: string;
    /**
     *
     * @type {boolean}
     * @memberof LoginViewModel
     */
    rememberMe: boolean;
}
/**
 *
 * @export
 * @interface NotificationViewModel
 */
export interface NotificationViewModel {
    /**
     *
     * @type {number}
     * @memberof NotificationViewModel
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof NotificationViewModel
     */
    sender?: string;
    /**
     *
     * @type {string}
     * @memberof NotificationViewModel
     */
    owner?: string;
    /**
     *
     * @type {CategoryState}
     * @memberof NotificationViewModel
     */
    category?: CategoryState;
    /**
     *
     * @type {string}
     * @memberof NotificationViewModel
     */
    body?: string;
    /**
     *
     * @type {boolean}
     * @memberof NotificationViewModel
     */
    hasSeen?: boolean;
    /**
     *
     * @type {Date}
     * @memberof NotificationViewModel
     */
    date?: Date;
}
/**
 *
 * @export
 * @interface NotificationsSettingDto
 */
export interface NotificationsSettingDto {
    /**
     *
     * @type {string}
     * @memberof NotificationsSettingDto
     */
    category?: string;
    /**
     *
     * @type {boolean}
     * @memberof NotificationsSettingDto
     */
    isEnabled?: boolean;
}
/**
 *
 * @export
 * @interface RateSolutionModel
 */
export interface RateSolutionModel {
    /**
     *
     * @type {number}
     * @memberof RateSolutionModel
     */
    rating?: number;
    /**
     *
     * @type {string}
     * @memberof RateSolutionModel
     */
    lecturerComment?: string;
}
/**
 *
 * @export
 * @interface RegisterExpertViewModel
 */
export interface RegisterExpertViewModel {
    /**
     *
     * @type {string}
     * @memberof RegisterExpertViewModel
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof RegisterExpertViewModel
     */
    surname: string;
    /**
     *
     * @type {string}
     * @memberof RegisterExpertViewModel
     */
    middleName?: string;
    /**
     *
     * @type {string}
     * @memberof RegisterExpertViewModel
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof RegisterExpertViewModel
     */
    bio?: string;
    /**
     *
     * @type {string}
     * @memberof RegisterExpertViewModel
     */
    companyName?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof RegisterExpertViewModel
     */
    tags?: Array<string>;
}
/**
 *
 * @export
 * @interface RegisterViewModel
 */
export interface RegisterViewModel {
    /**
     *
     * @type {string}
     * @memberof RegisterViewModel
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof RegisterViewModel
     */
    surname: string;
    /**
     *
     * @type {string}
     * @memberof RegisterViewModel
     */
    middleName?: string;
    /**
     *
     * @type {string}
     * @memberof RegisterViewModel
     */
    email: string;
}
/**
 *
 * @export
 * @interface RequestPasswordRecoveryViewModel
 */
export interface RequestPasswordRecoveryViewModel {
    /**
     *
     * @type {string}
     * @memberof RequestPasswordRecoveryViewModel
     */
    email: string;
}
/**
 *
 * @export
 * @interface ResetPasswordViewModel
 */
export interface ResetPasswordViewModel {
    /**
     *
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    userId: string;
    /**
     *
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    token: string;
    /**
     *
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    password: string;
    /**
     *
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    passwordConfirm: string;
}
/**
 *
 * @export
 * @interface Result
 */
export interface Result {
    /**
     *
     * @type {boolean}
     * @memberof Result
     */
    succeeded?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof Result
     */
    errors?: Array<string>;
}
/**
 *
 * @export
 * @interface Solution
 */
export interface Solution {
    /**
     *
     * @type {number}
     * @memberof Solution
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof Solution
     */
    githubUrl?: string;
    /**
     *
     * @type {string}
     * @memberof Solution
     */
    comment?: string;
    /**
     *
     * @type {SolutionState}
     * @memberof Solution
     */
    state?: SolutionState;
    /**
     *
     * @type {number}
     * @memberof Solution
     */
    rating?: number;
    /**
     *
     * @type {string}
     * @memberof Solution
     */
    studentId?: string;
    /**
     *
     * @type {string}
     * @memberof Solution
     */
    lecturerId?: string;
    /**
     *
     * @type {number}
     * @memberof Solution
     */
    groupId?: number;
    /**
     *
     * @type {number}
     * @memberof Solution
     */
    taskId?: number;
    /**
     *
     * @type {Date}
     * @memberof Solution
     */
    publicationDate?: Date;
    /**
     *
     * @type {Date}
     * @memberof Solution
     */
    ratingDate?: Date;
    /**
     *
     * @type {string}
     * @memberof Solution
     */
    lecturerComment?: string;
}
/**
 *
 * @export
 * @interface SolutionActualityDto
 */
export interface SolutionActualityDto {
    /**
     *
     * @type {SolutionActualityPart}
     * @memberof SolutionActualityDto
     */
    commitsActuality?: SolutionActualityPart;
    /**
     *
     * @type {SolutionActualityPart}
     * @memberof SolutionActualityDto
     */
    testsActuality?: SolutionActualityPart;
}
/**
 *
 * @export
 * @interface SolutionActualityPart
 */
export interface SolutionActualityPart {
    /**
     *
     * @type {boolean}
     * @memberof SolutionActualityPart
     */
    isActual?: boolean;
    /**
     *
     * @type {string}
     * @memberof SolutionActualityPart
     */
    comment?: string;
    /**
     *
     * @type {string}
     * @memberof SolutionActualityPart
     */
    additionalData?: string;
}
/**
 *
 * @export
 * @interface SolutionPreviewView
 */
export interface SolutionPreviewView {
    /**
     *
     * @type {number}
     * @memberof SolutionPreviewView
     */
    solutionId?: number;
    /**
     *
     * @type {AccountDataDto}
     * @memberof SolutionPreviewView
     */
    student?: AccountDataDto;
    /**
     *
     * @type {string}
     * @memberof SolutionPreviewView
     */
    courseTitle?: string;
    /**
     *
     * @type {number}
     * @memberof SolutionPreviewView
     */
    courseId?: number;
    /**
     *
     * @type {string}
     * @memberof SolutionPreviewView
     */
    homeworkTitle?: string;
    /**
     *
     * @type {string}
     * @memberof SolutionPreviewView
     */
    taskTitle?: string;
    /**
     *
     * @type {number}
     * @memberof SolutionPreviewView
     */
    taskId?: number;
    /**
     *
     * @type {Date}
     * @memberof SolutionPreviewView
     */
    publicationDate?: Date;
    /**
     *
     * @type {number}
     * @memberof SolutionPreviewView
     */
    groupId?: number;
    /**
     *
     * @type {boolean}
     * @memberof SolutionPreviewView
     */
    isFirstTry?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof SolutionPreviewView
     */
    sentAfterDeadline?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof SolutionPreviewView
     */
    isCourseCompleted?: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum SolutionState {
    NUMBER_0 = <any> 0,
    NUMBER_1 = <any> 1,
    NUMBER_2 = <any> 2
}
/**
 *
 * @export
 * @interface SolutionViewModel
 */
export interface SolutionViewModel {
    /**
     *
     * @type {string}
     * @memberof SolutionViewModel
     */
    githubUrl?: string;
    /**
     *
     * @type {string}
     * @memberof SolutionViewModel
     */
    comment?: string;
    /**
     *
     * @type {string}
     * @memberof SolutionViewModel
     */
    studentId?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof SolutionViewModel
     */
    groupMateIds?: Array<string>;
    /**
     *
     * @type {Date}
     * @memberof SolutionViewModel
     */
    publicationDate?: Date;
    /**
     *
     * @type {string}
     * @memberof SolutionViewModel
     */
    lecturerComment?: string;
    /**
     *
     * @type {number}
     * @memberof SolutionViewModel
     */
    rating?: number;
    /**
     *
     * @type {Date}
     * @memberof SolutionViewModel
     */
    ratingDate?: Date;
}
/**
 *
 * @export
 * @interface StatisticsCourseHomeworksModel
 */
export interface StatisticsCourseHomeworksModel {
    /**
     *
     * @type {number}
     * @memberof StatisticsCourseHomeworksModel
     */
    id?: number;
    /**
     *
     * @type {Array<StatisticsCourseTasksModel>}
     * @memberof StatisticsCourseHomeworksModel
     */
    tasks?: Array<StatisticsCourseTasksModel>;
}
/**
 *
 * @export
 * @interface StatisticsCourseMatesModel
 */
export interface StatisticsCourseMatesModel {
    /**
     *
     * @type {string}
     * @memberof StatisticsCourseMatesModel
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof StatisticsCourseMatesModel
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof StatisticsCourseMatesModel
     */
    surname?: string;
    /**
     *
     * @type {Array<AccountDataDto>}
     * @memberof StatisticsCourseMatesModel
     */
    reviewers?: Array<AccountDataDto>;
    /**
     *
     * @type {Array<StatisticsCourseHomeworksModel>}
     * @memberof StatisticsCourseMatesModel
     */
    homeworks?: Array<StatisticsCourseHomeworksModel>;
}
/**
 *
 * @export
 * @interface StatisticsCourseMeasureSolutionModel
 */
export interface StatisticsCourseMeasureSolutionModel {
    /**
     *
     * @type {number}
     * @memberof StatisticsCourseMeasureSolutionModel
     */
    rating?: number;
    /**
     *
     * @type {number}
     * @memberof StatisticsCourseMeasureSolutionModel
     */
    taskId?: number;
    /**
     *
     * @type {Date}
     * @memberof StatisticsCourseMeasureSolutionModel
     */
    publicationDate?: Date;
}
/**
 *
 * @export
 * @interface StatisticsCourseTasksModel
 */
export interface StatisticsCourseTasksModel {
    /**
     *
     * @type {number}
     * @memberof StatisticsCourseTasksModel
     */
    id?: number;
    /**
     *
     * @type {Array<Solution>}
     * @memberof StatisticsCourseTasksModel
     */
    solution?: Array<Solution>;
}
/**
 *
 * @export
 * @interface StatisticsLecturersModel
 */
export interface StatisticsLecturersModel {
    /**
     *
     * @type {AccountDataDto}
     * @memberof StatisticsLecturersModel
     */
    lecturer?: AccountDataDto;
    /**
     *
     * @type {number}
     * @memberof StatisticsLecturersModel
     */
    numberOfCheckedSolutions?: number;
    /**
     *
     * @type {number}
     * @memberof StatisticsLecturersModel
     */
    numberOfCheckedUniqueSolutions?: number;
}
/**
 *
 * @export
 * @interface SystemInfo
 */
export interface SystemInfo {
    /**
     *
     * @type {string}
     * @memberof SystemInfo
     */
    service?: string;
    /**
     *
     * @type {boolean}
     * @memberof SystemInfo
     */
    isAvailable?: boolean;
}
/**
 *
 * @export
 * @interface TaskDeadlineDto
 */
export interface TaskDeadlineDto {
    /**
     *
     * @type {number}
     * @memberof TaskDeadlineDto
     */
    taskId?: number;
    /**
     *
     * @type {Array<string>}
     * @memberof TaskDeadlineDto
     */
    tags?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof TaskDeadlineDto
     */
    taskTitle?: string;
    /**
     *
     * @type {string}
     * @memberof TaskDeadlineDto
     */
    courseTitle?: string;
    /**
     *
     * @type {number}
     * @memberof TaskDeadlineDto
     */
    courseId?: number;
    /**
     *
     * @type {number}
     * @memberof TaskDeadlineDto
     */
    homeworkId?: number;
    /**
     *
     * @type {number}
     * @memberof TaskDeadlineDto
     */
    maxRating?: number;
    /**
     *
     * @type {Date}
     * @memberof TaskDeadlineDto
     */
    publicationDate?: Date;
    /**
     *
     * @type {Date}
     * @memberof TaskDeadlineDto
     */
    deadlineDate?: Date;
}
/**
 *
 * @export
 * @interface TaskDeadlineView
 */
export interface TaskDeadlineView {
    /**
     *
     * @type {TaskDeadlineDto}
     * @memberof TaskDeadlineView
     */
    deadline?: TaskDeadlineDto;
    /**
     *
     * @type {SolutionState}
     * @memberof TaskDeadlineView
     */
    solutionState?: SolutionState;
    /**
     *
     * @type {number}
     * @memberof TaskDeadlineView
     */
    rating?: number;
    /**
     *
     * @type {number}
     * @memberof TaskDeadlineView
     */
    maxRating?: number;
    /**
     *
     * @type {boolean}
     * @memberof TaskDeadlineView
     */
    deadlinePast?: boolean;
}
/**
 *
 * @export
 * @interface TaskSolutionStatisticsPageData
 */
export interface TaskSolutionStatisticsPageData {
    /**
     *
     * @type {Array<TaskSolutions>}
     * @memberof TaskSolutionStatisticsPageData
     */
    taskSolutions?: Array<TaskSolutions>;
    /**
     *
     * @type {number}
     * @memberof TaskSolutionStatisticsPageData
     */
    courseId?: number;
    /**
     *
     * @type {Array<HomeworksGroupSolutionStats>}
     * @memberof TaskSolutionStatisticsPageData
     */
    statsForTasks?: Array<HomeworksGroupSolutionStats>;
}
/**
 *
 * @export
 * @interface TaskSolutions
 */
export interface TaskSolutions {
    /**
     *
     * @type {number}
     * @memberof TaskSolutions
     */
    taskId?: number;
    /**
     *
     * @type {Array<UserTaskSolutions>}
     * @memberof TaskSolutions
     */
    studentSolutions?: Array<UserTaskSolutions>;
}
/**
 *
 * @export
 * @interface TaskSolutionsStats
 */
export interface TaskSolutionsStats {
    /**
     *
     * @type {number}
     * @memberof TaskSolutionsStats
     */
    taskId?: number;
    /**
     *
     * @type {number}
     * @memberof TaskSolutionsStats
     */
    countUnratedSolutions?: number;
    /**
     *
     * @type {string}
     * @memberof TaskSolutionsStats
     */
    title?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof TaskSolutionsStats
     */
    tags?: Array<string>;
}
/**
 *
 * @export
 * @interface TokenCredentials
 */
export interface TokenCredentials {
    /**
     *
     * @type {string}
     * @memberof TokenCredentials
     */
    accessToken?: string;
}
/**
 *
 * @export
 * @interface TokenCredentialsResult
 */
export interface TokenCredentialsResult {
    /**
     *
     * @type {TokenCredentials}
     * @memberof TokenCredentialsResult
     */
    value?: TokenCredentials;
    /**
     *
     * @type {boolean}
     * @memberof TokenCredentialsResult
     */
    succeeded?: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof TokenCredentialsResult
     */
    errors?: Array<string>;
}
/**
 *
 * @export
 * @interface UnratedSolutionPreviews
 */
export interface UnratedSolutionPreviews {
    /**
     *
     * @type {Array<SolutionPreviewView>}
     * @memberof UnratedSolutionPreviews
     */
    unratedSolutions?: Array<SolutionPreviewView>;
}
/**
 *
 * @export
 * @interface UpdateCourseViewModel
 */
export interface UpdateCourseViewModel {
    /**
     *
     * @type {string}
     * @memberof UpdateCourseViewModel
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof UpdateCourseViewModel
     */
    groupName?: string;
    /**
     *
     * @type {boolean}
     * @memberof UpdateCourseViewModel
     */
    isOpen: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UpdateCourseViewModel
     */
    isCompleted?: boolean;
}
/**
 *
 * @export
 * @interface UpdateExpertTagsDTO
 */
export interface UpdateExpertTagsDTO {
    /**
     *
     * @type {string}
     * @memberof UpdateExpertTagsDTO
     */
    expertId?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof UpdateExpertTagsDTO
     */
    tags?: Array<string>;
}
/**
 *
 * @export
 * @interface UpdateGroupViewModel
 */
export interface UpdateGroupViewModel {
    /**
     *
     * @type {string}
     * @memberof UpdateGroupViewModel
     */
    name?: string;
    /**
     *
     * @type {Array<number>}
     * @memberof UpdateGroupViewModel
     */
    tasks?: Array<number>;
    /**
     *
     * @type {Array<GroupMateViewModel>}
     * @memberof UpdateGroupViewModel
     */
    groupMates?: Array<GroupMateViewModel>;
}
/**
 *
 * @export
 * @interface UrlDto
 */
export interface UrlDto {
    /**
     *
     * @type {string}
     * @memberof UrlDto
     */
    url?: string;
}
/**
 *
 * @export
 * @interface UserDataDto
 */
export interface UserDataDto {
    /**
     *
     * @type {AccountDataDto}
     * @memberof UserDataDto
     */
    userData?: AccountDataDto;
    /**
     *
     * @type {Array<CourseEvents>}
     * @memberof UserDataDto
     */
    courseEvents?: Array<CourseEvents>;
    /**
     *
     * @type {Array<TaskDeadlineView>}
     * @memberof UserDataDto
     */
    taskDeadlines?: Array<TaskDeadlineView>;
}
/**
 *
 * @export
 * @interface UserTaskSolutions
 */
export interface UserTaskSolutions {
    /**
     *
     * @type {Array<GetSolutionModel>}
     * @memberof UserTaskSolutions
     */
    solutions?: Array<GetSolutionModel>;
    /**
     *
     * @type {AccountDataDto}
     * @memberof UserTaskSolutions
     */
    user?: AccountDataDto;
}
/**
 *
 * @export
 * @interface UserTaskSolutions2
 */
export interface UserTaskSolutions2 {
    /**
     *
     * @type {number}
     * @memberof UserTaskSolutions2
     */
    maxRating?: number;
    /**
     *
     * @type {string}
     * @memberof UserTaskSolutions2
     */
    title?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof UserTaskSolutions2
     */
    tags?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof UserTaskSolutions2
     */
    taskId?: string;
    /**
     *
     * @type {Array<GetSolutionModel>}
     * @memberof UserTaskSolutions2
     */
    solutions?: Array<GetSolutionModel>;
}
/**
 *
 * @export
 * @interface UserTaskSolutionsPageData
 */
export interface UserTaskSolutionsPageData {
    /**
     *
     * @type {number}
     * @memberof UserTaskSolutionsPageData
     */
    courseId?: number;
    /**
     *
     * @type {Array<AccountDataDto>}
     * @memberof UserTaskSolutionsPageData
     */
    courseMates?: Array<AccountDataDto>;
    /**
     *
     * @type {Array<HomeworksGroupUserTaskSolutions>}
     * @memberof UserTaskSolutionsPageData
     */
    taskSolutions?: Array<HomeworksGroupUserTaskSolutions>;
}
/**
 *
 * @export
 * @interface WorkspaceViewModel
 */
export interface WorkspaceViewModel {
    /**
     *
     * @type {Array<AccountDataDto>}
     * @memberof WorkspaceViewModel
     */
    students?: Array<AccountDataDto>;
    /**
     *
     * @type {Array<HomeworkViewModel>}
     * @memberof WorkspaceViewModel
     */
    homeworks?: Array<HomeworkViewModel>;
}
/**
 * AccountApi - fetch parameter creator
 * @export
 */
export const AccountApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [code]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountAuthorizeGithub(code?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/github/authorize`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {EditAccountViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountEdit(body?: EditAccountViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/edit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EditAccountViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetAllStudents(options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/getAllStudents`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UrlDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetGithubLoginUrl(body?: UrlDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/github/url`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UrlDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetUserData(options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/getUserData`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetUserDataById(userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling accountGetUserDataById.');
            }
            const localVarPath = `/api/Account/getUserData/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {InviteLecturerViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountInviteNewLecturer(body?: InviteLecturerViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/inviteNewLecturer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InviteLecturerViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {LoginViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLogin(body?: LoginViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LoginViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRefreshToken(options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/refreshToken`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {RegisterViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRegister(body?: RegisterViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/register`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RegisterViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {RequestPasswordRecoveryViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRequestPasswordRecovery(body?: RequestPasswordRecoveryViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/requestPasswordRecovery`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RequestPasswordRecoveryViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {ResetPasswordViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResetPassword(body?: ResetPasswordViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/resetPassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ResetPasswordViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} [code]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountAuthorizeGithub(code?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GithubCredentials> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).accountAuthorizeGithub(code, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {EditAccountViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountEdit(body?: EditAccountViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).accountEdit(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetAllStudents(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AccountDataDto>> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).accountGetAllStudents(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {UrlDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetGithubLoginUrl(body?: UrlDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UrlDto> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).accountGetGithubLoginUrl(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetUserData(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDataDto> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).accountGetUserData(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetUserDataById(userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountDataDto> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).accountGetUserDataById(userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {InviteLecturerViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountInviteNewLecturer(body?: InviteLecturerViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).accountInviteNewLecturer(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {LoginViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLogin(body?: LoginViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokenCredentialsResult> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).accountLogin(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRefreshToken(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokenCredentialsResult> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).accountRefreshToken(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {RegisterViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRegister(body?: RegisterViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).accountRegister(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {RequestPasswordRecoveryViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRequestPasswordRecovery(body?: RequestPasswordRecoveryViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).accountRequestPasswordRecovery(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {ResetPasswordViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResetPassword(body?: ResetPasswordViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).accountResetPassword(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {string} [code]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountAuthorizeGithub(code?: string, options?: any) {
            return AccountApiFp(configuration).accountAuthorizeGithub(code, options)(fetch, basePath);
        },
        /**
         *
         * @param {EditAccountViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountEdit(body?: EditAccountViewModel, options?: any) {
            return AccountApiFp(configuration).accountEdit(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetAllStudents(options?: any) {
            return AccountApiFp(configuration).accountGetAllStudents(options)(fetch, basePath);
        },
        /**
         *
         * @param {UrlDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetGithubLoginUrl(body?: UrlDto, options?: any) {
            return AccountApiFp(configuration).accountGetGithubLoginUrl(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetUserData(options?: any) {
            return AccountApiFp(configuration).accountGetUserData(options)(fetch, basePath);
        },
        /**
         *
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetUserDataById(userId: string, options?: any) {
            return AccountApiFp(configuration).accountGetUserDataById(userId, options)(fetch, basePath);
        },
        /**
         *
         * @param {InviteLecturerViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountInviteNewLecturer(body?: InviteLecturerViewModel, options?: any) {
            return AccountApiFp(configuration).accountInviteNewLecturer(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {LoginViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountLogin(body?: LoginViewModel, options?: any) {
            return AccountApiFp(configuration).accountLogin(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRefreshToken(options?: any) {
            return AccountApiFp(configuration).accountRefreshToken(options)(fetch, basePath);
        },
        /**
         *
         * @param {RegisterViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRegister(body?: RegisterViewModel, options?: any) {
            return AccountApiFp(configuration).accountRegister(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {RequestPasswordRecoveryViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountRequestPasswordRecovery(body?: RequestPasswordRecoveryViewModel, options?: any) {
            return AccountApiFp(configuration).accountRequestPasswordRecovery(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {ResetPasswordViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountResetPassword(body?: ResetPasswordViewModel, options?: any) {
            return AccountApiFp(configuration).accountResetPassword(body, options)(fetch, basePath);
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     *
     * @param {string} [code]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountAuthorizeGithub(code?: string, options?: any) {
        return AccountApiFp(this.configuration).accountAuthorizeGithub(code, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {EditAccountViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountEdit(body?: EditAccountViewModel, options?: any) {
        return AccountApiFp(this.configuration).accountEdit(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetAllStudents(options?: any) {
        return AccountApiFp(this.configuration).accountGetAllStudents(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {UrlDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetGithubLoginUrl(body?: UrlDto, options?: any) {
        return AccountApiFp(this.configuration).accountGetGithubLoginUrl(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetUserData(options?: any) {
        return AccountApiFp(this.configuration).accountGetUserData(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetUserDataById(userId: string, options?: any) {
        return AccountApiFp(this.configuration).accountGetUserDataById(userId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {InviteLecturerViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountInviteNewLecturer(body?: InviteLecturerViewModel, options?: any) {
        return AccountApiFp(this.configuration).accountInviteNewLecturer(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {LoginViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountLogin(body?: LoginViewModel, options?: any) {
        return AccountApiFp(this.configuration).accountLogin(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountRefreshToken(options?: any) {
        return AccountApiFp(this.configuration).accountRefreshToken(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {RegisterViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountRegister(body?: RegisterViewModel, options?: any) {
        return AccountApiFp(this.configuration).accountRegister(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {RequestPasswordRecoveryViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountRequestPasswordRecovery(body?: RequestPasswordRecoveryViewModel, options?: any) {
        return AccountApiFp(this.configuration).accountRequestPasswordRecovery(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {ResetPasswordViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountResetPassword(body?: ResetPasswordViewModel, options?: any) {
        return AccountApiFp(this.configuration).accountResetPassword(body, options)(this.fetch, this.basePath);
    }

}
/**
 * CourseGroupsApi - fetch parameter creator
 * @export
 */
export const CourseGroupsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} courseId
         * @param {number} groupId
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsAddStudentInGroup(courseId: number, groupId: number, userId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling courseGroupsAddStudentInGroup.');
            }
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling courseGroupsAddStudentInGroup.');
            }
            const localVarPath = `/api/CourseGroups/{courseId}/addStudentInGroup/{groupId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {CreateGroupViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsCreateCourseGroup(courseId: number, body?: CreateGroupViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling courseGroupsCreateCourseGroup.');
            }
            const localVarPath = `/api/CourseGroups/{courseId}/create`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateGroupViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {number} groupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsDeleteCourseGroup(courseId: number, groupId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling courseGroupsDeleteCourseGroup.');
            }
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling courseGroupsDeleteCourseGroup.');
            }
            const localVarPath = `/api/CourseGroups/{courseId}/delete/{groupId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsGetAllCourseGroups(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling courseGroupsGetAllCourseGroups.');
            }
            const localVarPath = `/api/CourseGroups/{courseId}/getAll`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsGetCourseGroupsById(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling courseGroupsGetCourseGroupsById.');
            }
            const localVarPath = `/api/CourseGroups/{courseId}/get`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} groupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsGetGroup(groupId: number, options: any = {}): FetchArgs {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling courseGroupsGetGroup.');
            }
            const localVarPath = `/api/CourseGroups/get/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} groupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsGetGroupTasks(groupId: number, options: any = {}): FetchArgs {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling courseGroupsGetGroupTasks.');
            }
            const localVarPath = `/api/CourseGroups/getTasks/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {number} groupId
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsRemoveStudentFromGroup(courseId: number, groupId: number, userId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling courseGroupsRemoveStudentFromGroup.');
            }
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling courseGroupsRemoveStudentFromGroup.');
            }
            const localVarPath = `/api/CourseGroups/{courseId}/removeStudentFromGroup/{groupId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {number} groupId
         * @param {UpdateGroupViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsUpdateCourseGroup(courseId: number, groupId: number, body?: UpdateGroupViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling courseGroupsUpdateCourseGroup.');
            }
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling courseGroupsUpdateCourseGroup.');
            }
            const localVarPath = `/api/CourseGroups/{courseId}/update/{groupId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateGroupViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseGroupsApi - functional programming interface
 * @export
 */
export const CourseGroupsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} courseId
         * @param {number} groupId
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsAddStudentInGroup(courseId: number, groupId: number, userId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).courseGroupsAddStudentInGroup(courseId, groupId, userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {CreateGroupViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsCreateCourseGroup(courseId: number, body?: CreateGroupViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).courseGroupsCreateCourseGroup(courseId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {number} groupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsDeleteCourseGroup(courseId: number, groupId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).courseGroupsDeleteCourseGroup(courseId, groupId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsGetAllCourseGroups(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GroupViewModel>> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).courseGroupsGetAllCourseGroups(courseId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsGetCourseGroupsById(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GroupViewModel> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).courseGroupsGetCourseGroupsById(courseId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} groupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsGetGroup(groupId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GroupViewModel> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).courseGroupsGetGroup(groupId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} groupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsGetGroupTasks(groupId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<number>> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).courseGroupsGetGroupTasks(groupId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {number} groupId
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsRemoveStudentFromGroup(courseId: number, groupId: number, userId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).courseGroupsRemoveStudentFromGroup(courseId, groupId, userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {number} groupId
         * @param {UpdateGroupViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsUpdateCourseGroup(courseId: number, groupId: number, body?: UpdateGroupViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).courseGroupsUpdateCourseGroup(courseId, groupId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CourseGroupsApi - factory interface
 * @export
 */
export const CourseGroupsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {number} courseId
         * @param {number} groupId
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsAddStudentInGroup(courseId: number, groupId: number, userId?: string, options?: any) {
            return CourseGroupsApiFp(configuration).courseGroupsAddStudentInGroup(courseId, groupId, userId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {CreateGroupViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsCreateCourseGroup(courseId: number, body?: CreateGroupViewModel, options?: any) {
            return CourseGroupsApiFp(configuration).courseGroupsCreateCourseGroup(courseId, body, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {number} groupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsDeleteCourseGroup(courseId: number, groupId: number, options?: any) {
            return CourseGroupsApiFp(configuration).courseGroupsDeleteCourseGroup(courseId, groupId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsGetAllCourseGroups(courseId: number, options?: any) {
            return CourseGroupsApiFp(configuration).courseGroupsGetAllCourseGroups(courseId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsGetCourseGroupsById(courseId: number, options?: any) {
            return CourseGroupsApiFp(configuration).courseGroupsGetCourseGroupsById(courseId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} groupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsGetGroup(groupId: number, options?: any) {
            return CourseGroupsApiFp(configuration).courseGroupsGetGroup(groupId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} groupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsGetGroupTasks(groupId: number, options?: any) {
            return CourseGroupsApiFp(configuration).courseGroupsGetGroupTasks(groupId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {number} groupId
         * @param {string} [userId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsRemoveStudentFromGroup(courseId: number, groupId: number, userId?: string, options?: any) {
            return CourseGroupsApiFp(configuration).courseGroupsRemoveStudentFromGroup(courseId, groupId, userId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {number} groupId
         * @param {UpdateGroupViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        courseGroupsUpdateCourseGroup(courseId: number, groupId: number, body?: UpdateGroupViewModel, options?: any) {
            return CourseGroupsApiFp(configuration).courseGroupsUpdateCourseGroup(courseId, groupId, body, options)(fetch, basePath);
        },
    };
};

/**
 * CourseGroupsApi - object-oriented interface
 * @export
 * @class CourseGroupsApi
 * @extends {BaseAPI}
 */
export class CourseGroupsApi extends BaseAPI {
    /**
     *
     * @param {number} courseId
     * @param {number} groupId
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public courseGroupsAddStudentInGroup(courseId: number, groupId: number, userId?: string, options?: any) {
        return CourseGroupsApiFp(this.configuration).courseGroupsAddStudentInGroup(courseId, groupId, userId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {CreateGroupViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public courseGroupsCreateCourseGroup(courseId: number, body?: CreateGroupViewModel, options?: any) {
        return CourseGroupsApiFp(this.configuration).courseGroupsCreateCourseGroup(courseId, body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public courseGroupsDeleteCourseGroup(courseId: number, groupId: number, options?: any) {
        return CourseGroupsApiFp(this.configuration).courseGroupsDeleteCourseGroup(courseId, groupId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public courseGroupsGetAllCourseGroups(courseId: number, options?: any) {
        return CourseGroupsApiFp(this.configuration).courseGroupsGetAllCourseGroups(courseId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public courseGroupsGetCourseGroupsById(courseId: number, options?: any) {
        return CourseGroupsApiFp(this.configuration).courseGroupsGetCourseGroupsById(courseId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public courseGroupsGetGroup(groupId: number, options?: any) {
        return CourseGroupsApiFp(this.configuration).courseGroupsGetGroup(groupId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public courseGroupsGetGroupTasks(groupId: number, options?: any) {
        return CourseGroupsApiFp(this.configuration).courseGroupsGetGroupTasks(groupId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {number} groupId
     * @param {string} [userId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public courseGroupsRemoveStudentFromGroup(courseId: number, groupId: number, userId?: string, options?: any) {
        return CourseGroupsApiFp(this.configuration).courseGroupsRemoveStudentFromGroup(courseId, groupId, userId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {number} groupId
     * @param {UpdateGroupViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public courseGroupsUpdateCourseGroup(courseId: number, groupId: number, body?: UpdateGroupViewModel, options?: any) {
        return CourseGroupsApiFp(this.configuration).courseGroupsUpdateCourseGroup(courseId, groupId, body, options)(this.fetch, this.basePath);
    }

}
/**
 * CoursesApi - fetch parameter creator
 * @export
 */
export const CoursesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} courseId
         * @param {string} lecturerEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesAcceptLecturer(courseId: number, lecturerEmail: string, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling coursesAcceptLecturer.');
            }
            // verify required parameter 'lecturerEmail' is not null or undefined
            if (lecturerEmail === null || lecturerEmail === undefined) {
                throw new RequiredError('lecturerEmail','Required parameter lecturerEmail was null or undefined when calling coursesAcceptLecturer.');
            }
            const localVarPath = `/api/Courses/acceptLecturer/{courseId}/{lecturerEmail}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lecturerEmail"}}`, encodeURIComponent(String(lecturerEmail)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {string} studentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesAcceptStudent(courseId: number, studentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling coursesAcceptStudent.');
            }
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling coursesAcceptStudent.');
            }
            const localVarPath = `/api/Courses/acceptStudent/{courseId}/{studentId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"studentId"}}`, encodeURIComponent(String(studentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {CreateCourseViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesCreateCourse(body?: CreateCourseViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Courses/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateCourseViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesDeleteCourse(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling coursesDeleteCourse.');
            }
            const localVarPath = `/api/Courses/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {string} mentorId
         * @param {EditMentorWorkspaceDTO} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesEditMentorWorkspace(courseId: number, mentorId: string, body?: EditMentorWorkspaceDTO, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling coursesEditMentorWorkspace.');
            }
            // verify required parameter 'mentorId' is not null or undefined
            if (mentorId === null || mentorId === undefined) {
                throw new RequiredError('mentorId','Required parameter mentorId was null or undefined when calling coursesEditMentorWorkspace.');
            }
            const localVarPath = `/api/Courses/editMentorWorkspace/{courseId}/{mentorId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"mentorId"}}`, encodeURIComponent(String(mentorId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EditMentorWorkspaceDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAllCourseData(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling coursesGetAllCourseData.');
            }
            const localVarPath = `/api/Courses/getAllData/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAllCourses(options: any = {}): FetchArgs {
            const localVarPath = `/api/Courses`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAllTagsForCourse(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling coursesGetAllTagsForCourse.');
            }
            const localVarPath = `/api/Courses/tags/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAllUserCourses(options: any = {}): FetchArgs {
            const localVarPath = `/api/Courses/userCourses`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetCourseData(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling coursesGetCourseData.');
            }
            const localVarPath = `/api/Courses/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetLecturersAvailableForCourse(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling coursesGetLecturersAvailableForCourse.');
            }
            const localVarPath = `/api/Courses/getLecturersAvailableForCourse/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {string} mentorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetMentorWorkspace(courseId: number, mentorId: string, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling coursesGetMentorWorkspace.');
            }
            // verify required parameter 'mentorId' is not null or undefined
            if (mentorId === null || mentorId === undefined) {
                throw new RequiredError('mentorId','Required parameter mentorId was null or undefined when calling coursesGetMentorWorkspace.');
            }
            const localVarPath = `/api/Courses/getMentorWorkspace/{courseId}/{mentorId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"mentorId"}}`, encodeURIComponent(String(mentorId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {string} studentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesRejectStudent(courseId: number, studentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling coursesRejectStudent.');
            }
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling coursesRejectStudent.');
            }
            const localVarPath = `/api/Courses/rejectStudent/{courseId}/{studentId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"studentId"}}`, encodeURIComponent(String(studentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesSignInCourse(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling coursesSignInCourse.');
            }
            const localVarPath = `/api/Courses/signInCourse/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {UpdateCourseViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesUpdateCourse(courseId: number, body?: UpdateCourseViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling coursesUpdateCourse.');
            }
            const localVarPath = `/api/Courses/update/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateCourseViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoursesApi - functional programming interface
 * @export
 */
export const CoursesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} courseId
         * @param {string} lecturerEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesAcceptLecturer(courseId: number, lecturerEmail: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).coursesAcceptLecturer(courseId, lecturerEmail, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {string} studentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesAcceptStudent(courseId: number, studentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).coursesAcceptStudent(courseId, studentId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {CreateCourseViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesCreateCourse(body?: CreateCourseViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).coursesCreateCourse(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesDeleteCourse(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).coursesDeleteCourse(courseId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {string} mentorId
         * @param {EditMentorWorkspaceDTO} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesEditMentorWorkspace(courseId: number, mentorId: string, body?: EditMentorWorkspaceDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).coursesEditMentorWorkspace(courseId, mentorId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAllCourseData(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CourseViewModel> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).coursesGetAllCourseData(courseId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAllCourses(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CoursePreviewView>> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).coursesGetAllCourses(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAllTagsForCourse(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).coursesGetAllTagsForCourse(courseId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAllUserCourses(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CoursePreviewView>> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).coursesGetAllUserCourses(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetCourseData(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CourseViewModel> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).coursesGetCourseData(courseId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetLecturersAvailableForCourse(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AccountDataDto>> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).coursesGetLecturersAvailableForCourse(courseId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {string} mentorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetMentorWorkspace(courseId: number, mentorId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WorkspaceViewModel> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).coursesGetMentorWorkspace(courseId, mentorId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {string} studentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesRejectStudent(courseId: number, studentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).coursesRejectStudent(courseId, studentId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesSignInCourse(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).coursesSignInCourse(courseId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {UpdateCourseViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesUpdateCourse(courseId: number, body?: UpdateCourseViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).coursesUpdateCourse(courseId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CoursesApi - factory interface
 * @export
 */
export const CoursesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {number} courseId
         * @param {string} lecturerEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesAcceptLecturer(courseId: number, lecturerEmail: string, options?: any) {
            return CoursesApiFp(configuration).coursesAcceptLecturer(courseId, lecturerEmail, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {string} studentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesAcceptStudent(courseId: number, studentId: string, options?: any) {
            return CoursesApiFp(configuration).coursesAcceptStudent(courseId, studentId, options)(fetch, basePath);
        },
        /**
         *
         * @param {CreateCourseViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesCreateCourse(body?: CreateCourseViewModel, options?: any) {
            return CoursesApiFp(configuration).coursesCreateCourse(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesDeleteCourse(courseId: number, options?: any) {
            return CoursesApiFp(configuration).coursesDeleteCourse(courseId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {string} mentorId
         * @param {EditMentorWorkspaceDTO} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesEditMentorWorkspace(courseId: number, mentorId: string, body?: EditMentorWorkspaceDTO, options?: any) {
            return CoursesApiFp(configuration).coursesEditMentorWorkspace(courseId, mentorId, body, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAllCourseData(courseId: number, options?: any) {
            return CoursesApiFp(configuration).coursesGetAllCourseData(courseId, options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAllCourses(options?: any) {
            return CoursesApiFp(configuration).coursesGetAllCourses(options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAllTagsForCourse(courseId: number, options?: any) {
            return CoursesApiFp(configuration).coursesGetAllTagsForCourse(courseId, options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetAllUserCourses(options?: any) {
            return CoursesApiFp(configuration).coursesGetAllUserCourses(options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetCourseData(courseId: number, options?: any) {
            return CoursesApiFp(configuration).coursesGetCourseData(courseId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetLecturersAvailableForCourse(courseId: number, options?: any) {
            return CoursesApiFp(configuration).coursesGetLecturersAvailableForCourse(courseId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {string} mentorId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesGetMentorWorkspace(courseId: number, mentorId: string, options?: any) {
            return CoursesApiFp(configuration).coursesGetMentorWorkspace(courseId, mentorId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {string} studentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesRejectStudent(courseId: number, studentId: string, options?: any) {
            return CoursesApiFp(configuration).coursesRejectStudent(courseId, studentId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesSignInCourse(courseId: number, options?: any) {
            return CoursesApiFp(configuration).coursesSignInCourse(courseId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {UpdateCourseViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coursesUpdateCourse(courseId: number, body?: UpdateCourseViewModel, options?: any) {
            return CoursesApiFp(configuration).coursesUpdateCourse(courseId, body, options)(fetch, basePath);
        },
    };
};

/**
 * CoursesApi - object-oriented interface
 * @export
 * @class CoursesApi
 * @extends {BaseAPI}
 */
export class CoursesApi extends BaseAPI {
    /**
     *
     * @param {number} courseId
     * @param {string} lecturerEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesAcceptLecturer(courseId: number, lecturerEmail: string, options?: any) {
        return CoursesApiFp(this.configuration).coursesAcceptLecturer(courseId, lecturerEmail, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {string} studentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesAcceptStudent(courseId: number, studentId: string, options?: any) {
        return CoursesApiFp(this.configuration).coursesAcceptStudent(courseId, studentId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {CreateCourseViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesCreateCourse(body?: CreateCourseViewModel, options?: any) {
        return CoursesApiFp(this.configuration).coursesCreateCourse(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesDeleteCourse(courseId: number, options?: any) {
        return CoursesApiFp(this.configuration).coursesDeleteCourse(courseId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {string} mentorId
     * @param {EditMentorWorkspaceDTO} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesEditMentorWorkspace(courseId: number, mentorId: string, body?: EditMentorWorkspaceDTO, options?: any) {
        return CoursesApiFp(this.configuration).coursesEditMentorWorkspace(courseId, mentorId, body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetAllCourseData(courseId: number, options?: any) {
        return CoursesApiFp(this.configuration).coursesGetAllCourseData(courseId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetAllCourses(options?: any) {
        return CoursesApiFp(this.configuration).coursesGetAllCourses(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetAllTagsForCourse(courseId: number, options?: any) {
        return CoursesApiFp(this.configuration).coursesGetAllTagsForCourse(courseId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetAllUserCourses(options?: any) {
        return CoursesApiFp(this.configuration).coursesGetAllUserCourses(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetCourseData(courseId: number, options?: any) {
        return CoursesApiFp(this.configuration).coursesGetCourseData(courseId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetLecturersAvailableForCourse(courseId: number, options?: any) {
        return CoursesApiFp(this.configuration).coursesGetLecturersAvailableForCourse(courseId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {string} mentorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesGetMentorWorkspace(courseId: number, mentorId: string, options?: any) {
        return CoursesApiFp(this.configuration).coursesGetMentorWorkspace(courseId, mentorId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {string} studentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesRejectStudent(courseId: number, studentId: string, options?: any) {
        return CoursesApiFp(this.configuration).coursesRejectStudent(courseId, studentId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesSignInCourse(courseId: number, options?: any) {
        return CoursesApiFp(this.configuration).coursesSignInCourse(courseId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {UpdateCourseViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public coursesUpdateCourse(courseId: number, body?: UpdateCourseViewModel, options?: any) {
        return CoursesApiFp(this.configuration).coursesUpdateCourse(courseId, body, options)(this.fetch, this.basePath);
    }

}
/**
 * ExpertsApi - fetch parameter creator
 * @export
 */
export const ExpertsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsGetAll(options: any = {}): FetchArgs {
            const localVarPath = `/api/Experts/getAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsGetIsProfileEdited(options: any = {}): FetchArgs {
            const localVarPath = `/api/Experts/isProfileEdited`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [expertEmail]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsGetToken(expertEmail?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/Experts/getToken`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (expertEmail !== undefined) {
                localVarQueryParameter['expertEmail'] = expertEmail;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {InviteExpertViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsInvite(body?: InviteExpertViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Experts/invite`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InviteExpertViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {TokenCredentials} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsLogin(body?: TokenCredentials, options: any = {}): FetchArgs {
            const localVarPath = `/api/Experts/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TokenCredentials" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {RegisterExpertViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsRegister(body?: RegisterExpertViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Experts/register`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RegisterExpertViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsSetProfileIsEdited(options: any = {}): FetchArgs {
            const localVarPath = `/api/Experts/setProfileIsEdited`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {UpdateExpertTagsDTO} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsUpdateTags(body?: UpdateExpertTagsDTO, options: any = {}): FetchArgs {
            const localVarPath = `/api/Experts/updateTags`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateExpertTagsDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertsApi - functional programming interface
 * @export
 */
export const ExpertsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsGetAll(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ExpertDataDTO>> {
            const localVarFetchArgs = ExpertsApiFetchParamCreator(configuration).expertsGetAll(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsGetIsProfileEdited(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BooleanResult> {
            const localVarFetchArgs = ExpertsApiFetchParamCreator(configuration).expertsGetIsProfileEdited(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [expertEmail]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsGetToken(expertEmail?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokenCredentialsResult> {
            const localVarFetchArgs = ExpertsApiFetchParamCreator(configuration).expertsGetToken(expertEmail, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {InviteExpertViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsInvite(body?: InviteExpertViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = ExpertsApiFetchParamCreator(configuration).expertsInvite(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {TokenCredentials} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsLogin(body?: TokenCredentials, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = ExpertsApiFetchParamCreator(configuration).expertsLogin(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {RegisterExpertViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsRegister(body?: RegisterExpertViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = ExpertsApiFetchParamCreator(configuration).expertsRegister(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsSetProfileIsEdited(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = ExpertsApiFetchParamCreator(configuration).expertsSetProfileIsEdited(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {UpdateExpertTagsDTO} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsUpdateTags(body?: UpdateExpertTagsDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = ExpertsApiFetchParamCreator(configuration).expertsUpdateTags(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ExpertsApi - factory interface
 * @export
 */
export const ExpertsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsGetAll(options?: any) {
            return ExpertsApiFp(configuration).expertsGetAll(options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsGetIsProfileEdited(options?: any) {
            return ExpertsApiFp(configuration).expertsGetIsProfileEdited(options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [expertEmail]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsGetToken(expertEmail?: string, options?: any) {
            return ExpertsApiFp(configuration).expertsGetToken(expertEmail, options)(fetch, basePath);
        },
        /**
         *
         * @param {InviteExpertViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsInvite(body?: InviteExpertViewModel, options?: any) {
            return ExpertsApiFp(configuration).expertsInvite(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {TokenCredentials} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsLogin(body?: TokenCredentials, options?: any) {
            return ExpertsApiFp(configuration).expertsLogin(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {RegisterExpertViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsRegister(body?: RegisterExpertViewModel, options?: any) {
            return ExpertsApiFp(configuration).expertsRegister(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsSetProfileIsEdited(options?: any) {
            return ExpertsApiFp(configuration).expertsSetProfileIsEdited(options)(fetch, basePath);
        },
        /**
         *
         * @param {UpdateExpertTagsDTO} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        expertsUpdateTags(body?: UpdateExpertTagsDTO, options?: any) {
            return ExpertsApiFp(configuration).expertsUpdateTags(body, options)(fetch, basePath);
        },
    };
};

/**
 * ExpertsApi - object-oriented interface
 * @export
 * @class ExpertsApi
 * @extends {BaseAPI}
 */
export class ExpertsApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsGetAll(options?: any) {
        return ExpertsApiFp(this.configuration).expertsGetAll(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsGetIsProfileEdited(options?: any) {
        return ExpertsApiFp(this.configuration).expertsGetIsProfileEdited(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [expertEmail]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsGetToken(expertEmail?: string, options?: any) {
        return ExpertsApiFp(this.configuration).expertsGetToken(expertEmail, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {InviteExpertViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsInvite(body?: InviteExpertViewModel, options?: any) {
        return ExpertsApiFp(this.configuration).expertsInvite(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {TokenCredentials} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsLogin(body?: TokenCredentials, options?: any) {
        return ExpertsApiFp(this.configuration).expertsLogin(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {RegisterExpertViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsRegister(body?: RegisterExpertViewModel, options?: any) {
        return ExpertsApiFp(this.configuration).expertsRegister(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsSetProfileIsEdited(options?: any) {
        return ExpertsApiFp(this.configuration).expertsSetProfileIsEdited(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {UpdateExpertTagsDTO} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public expertsUpdateTags(body?: UpdateExpertTagsDTO, options?: any) {
        return ExpertsApiFp(this.configuration).expertsUpdateTags(body, options)(this.fetch, this.basePath);
    }

}
/**
 * FilesApi - fetch parameter creator
 * @export
 */
export const FilesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} [courseId]
         * @param {string} [key]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesDeleteFile(courseId?: number, key?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/Files`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (courseId !== undefined) {
                localVarQueryParameter['courseId'] = courseId;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} [key]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesGetDownloadLink(key?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/Files/downloadLink`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {number} [homeworkId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesGetFilesInfo(courseId: number, homeworkId?: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling filesGetFilesInfo.');
            }
            const localVarPath = `/api/Files/filesInfo/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (homeworkId !== undefined) {
                localVarQueryParameter['homeworkId'] = homeworkId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [courseId]
         * @param {number} [homeworkId]
         * @param {Blob} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesUpload(courseId?: number, homeworkId?: number, file?: Blob, options: any = {}): FetchArgs {
            const localVarPath = `/api/Files/upload`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (courseId !== undefined) {
                localVarFormParams.set('CourseId', courseId as any);
            }

            if (homeworkId !== undefined) {
                localVarFormParams.set('HomeworkId', homeworkId as any);
            }

            if (file !== undefined) {
                localVarFormParams.set('File', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} [courseId]
         * @param {string} [key]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesDeleteFile(courseId?: number, key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = FilesApiFetchParamCreator(configuration).filesDeleteFile(courseId, key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {string} [key]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesGetDownloadLink(key?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = FilesApiFetchParamCreator(configuration).filesGetDownloadLink(key, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {number} [homeworkId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesGetFilesInfo(courseId: number, homeworkId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FileInfoDTO>> {
            const localVarFetchArgs = FilesApiFetchParamCreator(configuration).filesGetFilesInfo(courseId, homeworkId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} [courseId]
         * @param {number} [homeworkId]
         * @param {Blob} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesUpload(courseId?: number, homeworkId?: number, file?: Blob, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = FilesApiFetchParamCreator(configuration).filesUpload(courseId, homeworkId, file, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {number} [courseId]
         * @param {string} [key]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesDeleteFile(courseId?: number, key?: string, options?: any) {
            return FilesApiFp(configuration).filesDeleteFile(courseId, key, options)(fetch, basePath);
        },
        /**
         *
         * @param {string} [key]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesGetDownloadLink(key?: string, options?: any) {
            return FilesApiFp(configuration).filesGetDownloadLink(key, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {number} [homeworkId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesGetFilesInfo(courseId: number, homeworkId?: number, options?: any) {
            return FilesApiFp(configuration).filesGetFilesInfo(courseId, homeworkId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} [courseId]
         * @param {number} [homeworkId]
         * @param {Blob} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        filesUpload(courseId?: number, homeworkId?: number, file?: Blob, options?: any) {
            return FilesApiFp(configuration).filesUpload(courseId, homeworkId, file, options)(fetch, basePath);
        },
    };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     *
     * @param {number} [courseId]
     * @param {string} [key]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesDeleteFile(courseId?: number, key?: string, options?: any) {
        return FilesApiFp(this.configuration).filesDeleteFile(courseId, key, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {string} [key]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesGetDownloadLink(key?: string, options?: any) {
        return FilesApiFp(this.configuration).filesGetDownloadLink(key, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {number} [homeworkId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesGetFilesInfo(courseId: number, homeworkId?: number, options?: any) {
        return FilesApiFp(this.configuration).filesGetFilesInfo(courseId, homeworkId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} [courseId]
     * @param {number} [homeworkId]
     * @param {Blob} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public filesUpload(courseId?: number, homeworkId?: number, file?: Blob, options?: any) {
        return FilesApiFp(this.configuration).filesUpload(courseId, homeworkId, file, options)(this.fetch, this.basePath);
    }

}
/**
 * HomeworksApi - fetch parameter creator
 * @export
 */
export const HomeworksApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} courseId
         * @param {CreateHomeworkViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeworksAddHomework(courseId: number, body?: CreateHomeworkViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling homeworksAddHomework.');
            }
            const localVarPath = `/api/Homeworks/{courseId}/add`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateHomeworkViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} homeworkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeworksDeleteHomework(homeworkId: number, options: any = {}): FetchArgs {
            // verify required parameter 'homeworkId' is not null or undefined
            if (homeworkId === null || homeworkId === undefined) {
                throw new RequiredError('homeworkId','Required parameter homeworkId was null or undefined when calling homeworksDeleteHomework.');
            }
            const localVarPath = `/api/Homeworks/delete/{homeworkId}`
                .replace(`{${"homeworkId"}}`, encodeURIComponent(String(homeworkId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} homeworkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeworksGetForEditingHomework(homeworkId: number, options: any = {}): FetchArgs {
            // verify required parameter 'homeworkId' is not null or undefined
            if (homeworkId === null || homeworkId === undefined) {
                throw new RequiredError('homeworkId','Required parameter homeworkId was null or undefined when calling homeworksGetForEditingHomework.');
            }
            const localVarPath = `/api/Homeworks/getForEditing/{homeworkId}`
                .replace(`{${"homeworkId"}}`, encodeURIComponent(String(homeworkId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} homeworkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeworksGetHomework(homeworkId: number, options: any = {}): FetchArgs {
            // verify required parameter 'homeworkId' is not null or undefined
            if (homeworkId === null || homeworkId === undefined) {
                throw new RequiredError('homeworkId','Required parameter homeworkId was null or undefined when calling homeworksGetHomework.');
            }
            const localVarPath = `/api/Homeworks/get/{homeworkId}`
                .replace(`{${"homeworkId"}}`, encodeURIComponent(String(homeworkId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} homeworkId
         * @param {CreateHomeworkViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeworksUpdateHomework(homeworkId: number, body?: CreateHomeworkViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'homeworkId' is not null or undefined
            if (homeworkId === null || homeworkId === undefined) {
                throw new RequiredError('homeworkId','Required parameter homeworkId was null or undefined when calling homeworksUpdateHomework.');
            }
            const localVarPath = `/api/Homeworks/update/{homeworkId}`
                .replace(`{${"homeworkId"}}`, encodeURIComponent(String(homeworkId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateHomeworkViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HomeworksApi - functional programming interface
 * @export
 */
export const HomeworksApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} courseId
         * @param {CreateHomeworkViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeworksAddHomework(courseId: number, body?: CreateHomeworkViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = HomeworksApiFetchParamCreator(configuration).homeworksAddHomework(courseId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} homeworkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeworksDeleteHomework(homeworkId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = HomeworksApiFetchParamCreator(configuration).homeworksDeleteHomework(homeworkId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} homeworkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeworksGetForEditingHomework(homeworkId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HomeworkViewModel> {
            const localVarFetchArgs = HomeworksApiFetchParamCreator(configuration).homeworksGetForEditingHomework(homeworkId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} homeworkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeworksGetHomework(homeworkId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HomeworkViewModel> {
            const localVarFetchArgs = HomeworksApiFetchParamCreator(configuration).homeworksGetHomework(homeworkId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} homeworkId
         * @param {CreateHomeworkViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeworksUpdateHomework(homeworkId: number, body?: CreateHomeworkViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = HomeworksApiFetchParamCreator(configuration).homeworksUpdateHomework(homeworkId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * HomeworksApi - factory interface
 * @export
 */
export const HomeworksApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {number} courseId
         * @param {CreateHomeworkViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeworksAddHomework(courseId: number, body?: CreateHomeworkViewModel, options?: any) {
            return HomeworksApiFp(configuration).homeworksAddHomework(courseId, body, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} homeworkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeworksDeleteHomework(homeworkId: number, options?: any) {
            return HomeworksApiFp(configuration).homeworksDeleteHomework(homeworkId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} homeworkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeworksGetForEditingHomework(homeworkId: number, options?: any) {
            return HomeworksApiFp(configuration).homeworksGetForEditingHomework(homeworkId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} homeworkId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeworksGetHomework(homeworkId: number, options?: any) {
            return HomeworksApiFp(configuration).homeworksGetHomework(homeworkId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} homeworkId
         * @param {CreateHomeworkViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        homeworksUpdateHomework(homeworkId: number, body?: CreateHomeworkViewModel, options?: any) {
            return HomeworksApiFp(configuration).homeworksUpdateHomework(homeworkId, body, options)(fetch, basePath);
        },
    };
};

/**
 * HomeworksApi - object-oriented interface
 * @export
 * @class HomeworksApi
 * @extends {BaseAPI}
 */
export class HomeworksApi extends BaseAPI {
    /**
     *
     * @param {number} courseId
     * @param {CreateHomeworkViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeworksApi
     */
    public homeworksAddHomework(courseId: number, body?: CreateHomeworkViewModel, options?: any) {
        return HomeworksApiFp(this.configuration).homeworksAddHomework(courseId, body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} homeworkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeworksApi
     */
    public homeworksDeleteHomework(homeworkId: number, options?: any) {
        return HomeworksApiFp(this.configuration).homeworksDeleteHomework(homeworkId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} homeworkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeworksApi
     */
    public homeworksGetForEditingHomework(homeworkId: number, options?: any) {
        return HomeworksApiFp(this.configuration).homeworksGetForEditingHomework(homeworkId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} homeworkId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeworksApi
     */
    public homeworksGetHomework(homeworkId: number, options?: any) {
        return HomeworksApiFp(this.configuration).homeworksGetHomework(homeworkId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} homeworkId
     * @param {CreateHomeworkViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeworksApi
     */
    public homeworksUpdateHomework(homeworkId: number, body?: CreateHomeworkViewModel, options?: any) {
        return HomeworksApiFp(this.configuration).homeworksUpdateHomework(homeworkId, body, options)(this.fetch, this.basePath);
    }

}
/**
 * NotificationsApi - fetch parameter creator
 * @export
 */
export const NotificationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {NotificationsSettingDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsChangeSetting(body?: NotificationsSettingDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/Notifications/settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NotificationsSettingDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/Notifications/get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsGetNewNotificationsCount(options: any = {}): FetchArgs {
            const localVarPath = `/api/Notifications/getNewNotificationsCount`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsGetSettings(options: any = {}): FetchArgs {
            const localVarPath = `/api/Notifications/settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {Array<number>} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsMarkAsSeen(body?: Array<number>, options: any = {}): FetchArgs {
            const localVarPath = `/api/Notifications/markAsSeen`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;number&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {NotificationsSettingDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsChangeSetting(body?: NotificationsSettingDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).notificationsChangeSetting(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CategorizedNotifications>> {
            const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).notificationsGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsGetNewNotificationsCount(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).notificationsGetNewNotificationsCount(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsGetSettings(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<NotificationsSettingDto>> {
            const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).notificationsGetSettings(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {Array<number>} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsMarkAsSeen(body?: Array<number>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).notificationsMarkAsSeen(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {NotificationsSettingDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsChangeSetting(body?: NotificationsSettingDto, options?: any) {
            return NotificationsApiFp(configuration).notificationsChangeSetting(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsGet(options?: any) {
            return NotificationsApiFp(configuration).notificationsGet(options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsGetNewNotificationsCount(options?: any) {
            return NotificationsApiFp(configuration).notificationsGetNewNotificationsCount(options)(fetch, basePath);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsGetSettings(options?: any) {
            return NotificationsApiFp(configuration).notificationsGetSettings(options)(fetch, basePath);
        },
        /**
         *
         * @param {Array<number>} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationsMarkAsSeen(body?: Array<number>, options?: any) {
            return NotificationsApiFp(configuration).notificationsMarkAsSeen(body, options)(fetch, basePath);
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     *
     * @param {NotificationsSettingDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsChangeSetting(body?: NotificationsSettingDto, options?: any) {
        return NotificationsApiFp(this.configuration).notificationsChangeSetting(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsGet(options?: any) {
        return NotificationsApiFp(this.configuration).notificationsGet(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsGetNewNotificationsCount(options?: any) {
        return NotificationsApiFp(this.configuration).notificationsGetNewNotificationsCount(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsGetSettings(options?: any) {
        return NotificationsApiFp(this.configuration).notificationsGetSettings(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {Array<number>} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public notificationsMarkAsSeen(body?: Array<number>, options?: any) {
        return NotificationsApiFp(this.configuration).notificationsMarkAsSeen(body, options)(this.fetch, this.basePath);
    }

}
/**
 * SolutionsApi - fetch parameter creator
 * @export
 */
export const SolutionsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} solutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsDeleteSolution(solutionId: number, options: any = {}): FetchArgs {
            // verify required parameter 'solutionId' is not null or undefined
            if (solutionId === null || solutionId === undefined) {
                throw new RequiredError('solutionId','Required parameter solutionId was null or undefined when calling solutionsDeleteSolution.');
            }
            const localVarPath = `/api/Solutions/delete/{solutionId}`
                .replace(`{${"solutionId"}}`, encodeURIComponent(String(solutionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [taskId]
         * @param {number} [solutionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetSolutionAchievement(taskId?: number, solutionId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/Solutions/solutionAchievement`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['taskId'] = taskId;
            }

            if (solutionId !== undefined) {
                localVarQueryParameter['solutionId'] = solutionId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} solutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetSolutionActuality(solutionId: number, options: any = {}): FetchArgs {
            // verify required parameter 'solutionId' is not null or undefined
            if (solutionId === null || solutionId === undefined) {
                throw new RequiredError('solutionId','Required parameter solutionId was null or undefined when calling solutionsGetSolutionActuality.');
            }
            const localVarPath = `/api/Solutions/actuality/{solutionId}`
                .replace(`{${"solutionId"}}`, encodeURIComponent(String(solutionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} solutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetSolutionById(solutionId: number, options: any = {}): FetchArgs {
            // verify required parameter 'solutionId' is not null or undefined
            if (solutionId === null || solutionId === undefined) {
                throw new RequiredError('solutionId','Required parameter solutionId was null or undefined when calling solutionsGetSolutionById.');
            }
            const localVarPath = `/api/Solutions/{solutionId}`
                .replace(`{${"solutionId"}}`, encodeURIComponent(String(solutionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {string} studentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetStudentSolution(taskId: number, studentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling solutionsGetStudentSolution.');
            }
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling solutionsGetStudentSolution.');
            }
            const localVarPath = `/api/Solutions/taskSolution/{taskId}/{studentId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)))
                .replace(`{${"studentId"}}`, encodeURIComponent(String(studentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetTaskSolutionsPageData(taskId: number, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling solutionsGetTaskSolutionsPageData.');
            }
            const localVarPath = `/api/Solutions/tasks/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} [taskId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetUnratedSolutions(taskId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/Solutions/unratedSolutions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['taskId'] = taskId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGiveUp(taskId: number, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling solutionsGiveUp.');
            }
            const localVarPath = `/api/Solutions/giveUp/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} solutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsMarkSolution(solutionId: number, options: any = {}): FetchArgs {
            // verify required parameter 'solutionId' is not null or undefined
            if (solutionId === null || solutionId === undefined) {
                throw new RequiredError('solutionId','Required parameter solutionId was null or undefined when calling solutionsMarkSolution.');
            }
            const localVarPath = `/api/Solutions/markSolutionFinal/{solutionId}`
                .replace(`{${"solutionId"}}`, encodeURIComponent(String(solutionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {SolutionViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsPostEmptySolutionWithRate(taskId: number, body?: SolutionViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling solutionsPostEmptySolutionWithRate.');
            }
            const localVarPath = `/api/Solutions/rateEmptySolution/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SolutionViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {SolutionViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsPostSolution(taskId: number, body?: SolutionViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling solutionsPostSolution.');
            }
            const localVarPath = `/api/Solutions/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SolutionViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} solutionId
         * @param {RateSolutionModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsRateSolution(solutionId: number, body?: RateSolutionModel, options: any = {}): FetchArgs {
            // verify required parameter 'solutionId' is not null or undefined
            if (solutionId === null || solutionId === undefined) {
                throw new RequiredError('solutionId','Required parameter solutionId was null or undefined when calling solutionsRateSolution.');
            }
            const localVarPath = `/api/Solutions/rateSolution/{solutionId}`
                .replace(`{${"solutionId"}}`, encodeURIComponent(String(solutionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RateSolutionModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SolutionsApi - functional programming interface
 * @export
 */
export const SolutionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} solutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsDeleteSolution(solutionId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).solutionsDeleteSolution(solutionId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} [taskId]
         * @param {number} [solutionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetSolutionAchievement(taskId?: number, solutionId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).solutionsGetSolutionAchievement(taskId, solutionId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} solutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetSolutionActuality(solutionId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SolutionActualityDto> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).solutionsGetSolutionActuality(solutionId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} solutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetSolutionById(solutionId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Solution> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).solutionsGetSolutionById(solutionId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {string} studentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetStudentSolution(taskId: number, studentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserTaskSolutionsPageData> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).solutionsGetStudentSolution(taskId, studentId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetTaskSolutionsPageData(taskId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskSolutionStatisticsPageData> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).solutionsGetTaskSolutionsPageData(taskId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} [taskId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetUnratedSolutions(taskId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UnratedSolutionPreviews> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).solutionsGetUnratedSolutions(taskId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGiveUp(taskId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).solutionsGiveUp(taskId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} solutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsMarkSolution(solutionId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).solutionsMarkSolution(solutionId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {SolutionViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsPostEmptySolutionWithRate(taskId: number, body?: SolutionViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).solutionsPostEmptySolutionWithRate(taskId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {SolutionViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsPostSolution(taskId: number, body?: SolutionViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).solutionsPostSolution(taskId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} solutionId
         * @param {RateSolutionModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsRateSolution(solutionId: number, body?: RateSolutionModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).solutionsRateSolution(solutionId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SolutionsApi - factory interface
 * @export
 */
export const SolutionsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {number} solutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsDeleteSolution(solutionId: number, options?: any) {
            return SolutionsApiFp(configuration).solutionsDeleteSolution(solutionId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} [taskId]
         * @param {number} [solutionId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetSolutionAchievement(taskId?: number, solutionId?: number, options?: any) {
            return SolutionsApiFp(configuration).solutionsGetSolutionAchievement(taskId, solutionId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} solutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetSolutionActuality(solutionId: number, options?: any) {
            return SolutionsApiFp(configuration).solutionsGetSolutionActuality(solutionId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} solutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetSolutionById(solutionId: number, options?: any) {
            return SolutionsApiFp(configuration).solutionsGetSolutionById(solutionId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} taskId
         * @param {string} studentId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetStudentSolution(taskId: number, studentId: string, options?: any) {
            return SolutionsApiFp(configuration).solutionsGetStudentSolution(taskId, studentId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetTaskSolutionsPageData(taskId: number, options?: any) {
            return SolutionsApiFp(configuration).solutionsGetTaskSolutionsPageData(taskId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} [taskId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGetUnratedSolutions(taskId?: number, options?: any) {
            return SolutionsApiFp(configuration).solutionsGetUnratedSolutions(taskId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsGiveUp(taskId: number, options?: any) {
            return SolutionsApiFp(configuration).solutionsGiveUp(taskId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} solutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsMarkSolution(solutionId: number, options?: any) {
            return SolutionsApiFp(configuration).solutionsMarkSolution(solutionId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} taskId
         * @param {SolutionViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsPostEmptySolutionWithRate(taskId: number, body?: SolutionViewModel, options?: any) {
            return SolutionsApiFp(configuration).solutionsPostEmptySolutionWithRate(taskId, body, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} taskId
         * @param {SolutionViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsPostSolution(taskId: number, body?: SolutionViewModel, options?: any) {
            return SolutionsApiFp(configuration).solutionsPostSolution(taskId, body, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} solutionId
         * @param {RateSolutionModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        solutionsRateSolution(solutionId: number, body?: RateSolutionModel, options?: any) {
            return SolutionsApiFp(configuration).solutionsRateSolution(solutionId, body, options)(fetch, basePath);
        },
    };
};

/**
 * SolutionsApi - object-oriented interface
 * @export
 * @class SolutionsApi
 * @extends {BaseAPI}
 */
export class SolutionsApi extends BaseAPI {
    /**
     *
     * @param {number} solutionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public solutionsDeleteSolution(solutionId: number, options?: any) {
        return SolutionsApiFp(this.configuration).solutionsDeleteSolution(solutionId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} [taskId]
     * @param {number} [solutionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public solutionsGetSolutionAchievement(taskId?: number, solutionId?: number, options?: any) {
        return SolutionsApiFp(this.configuration).solutionsGetSolutionAchievement(taskId, solutionId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} solutionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public solutionsGetSolutionActuality(solutionId: number, options?: any) {
        return SolutionsApiFp(this.configuration).solutionsGetSolutionActuality(solutionId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} solutionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public solutionsGetSolutionById(solutionId: number, options?: any) {
        return SolutionsApiFp(this.configuration).solutionsGetSolutionById(solutionId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} taskId
     * @param {string} studentId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public solutionsGetStudentSolution(taskId: number, studentId: string, options?: any) {
        return SolutionsApiFp(this.configuration).solutionsGetStudentSolution(taskId, studentId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public solutionsGetTaskSolutionsPageData(taskId: number, options?: any) {
        return SolutionsApiFp(this.configuration).solutionsGetTaskSolutionsPageData(taskId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} [taskId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public solutionsGetUnratedSolutions(taskId?: number, options?: any) {
        return SolutionsApiFp(this.configuration).solutionsGetUnratedSolutions(taskId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public solutionsGiveUp(taskId: number, options?: any) {
        return SolutionsApiFp(this.configuration).solutionsGiveUp(taskId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} solutionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public solutionsMarkSolution(solutionId: number, options?: any) {
        return SolutionsApiFp(this.configuration).solutionsMarkSolution(solutionId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} taskId
     * @param {SolutionViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public solutionsPostEmptySolutionWithRate(taskId: number, body?: SolutionViewModel, options?: any) {
        return SolutionsApiFp(this.configuration).solutionsPostEmptySolutionWithRate(taskId, body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} taskId
     * @param {SolutionViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public solutionsPostSolution(taskId: number, body?: SolutionViewModel, options?: any) {
        return SolutionsApiFp(this.configuration).solutionsPostSolution(taskId, body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} solutionId
     * @param {RateSolutionModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public solutionsRateSolution(solutionId: number, body?: RateSolutionModel, options?: any) {
        return SolutionsApiFp(this.configuration).solutionsRateSolution(solutionId, body, options)(this.fetch, this.basePath);
    }

}
/**
 * StatisticsApi - fetch parameter creator
 * @export
 */
export const StatisticsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsGetChartStatistics(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling statisticsGetChartStatistics.');
            }
            const localVarPath = `/api/Statistics/{courseId}/charts`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsGetCourseStatistics(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling statisticsGetCourseStatistics.');
            }
            const localVarPath = `/api/Statistics/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsGetLecturersStatistics(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling statisticsGetLecturersStatistics.');
            }
            const localVarPath = `/api/Statistics/{courseId}/lecturers`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsGetChartStatistics(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdvancedCourseStatisticsViewModel> {
            const localVarFetchArgs = StatisticsApiFetchParamCreator(configuration).statisticsGetChartStatistics(courseId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsGetCourseStatistics(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<StatisticsCourseMatesModel>> {
            const localVarFetchArgs = StatisticsApiFetchParamCreator(configuration).statisticsGetCourseStatistics(courseId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsGetLecturersStatistics(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<StatisticsLecturersModel>> {
            const localVarFetchArgs = StatisticsApiFetchParamCreator(configuration).statisticsGetLecturersStatistics(courseId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsGetChartStatistics(courseId: number, options?: any) {
            return StatisticsApiFp(configuration).statisticsGetChartStatistics(courseId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsGetCourseStatistics(courseId: number, options?: any) {
            return StatisticsApiFp(configuration).statisticsGetCourseStatistics(courseId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} courseId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticsGetLecturersStatistics(courseId: number, options?: any) {
            return StatisticsApiFp(configuration).statisticsGetLecturersStatistics(courseId, options)(fetch, basePath);
        },
    };
};

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
    /**
     *
     * @param {number} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsGetChartStatistics(courseId: number, options?: any) {
        return StatisticsApiFp(this.configuration).statisticsGetChartStatistics(courseId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsGetCourseStatistics(courseId: number, options?: any) {
        return StatisticsApiFp(this.configuration).statisticsGetCourseStatistics(courseId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} courseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public statisticsGetLecturersStatistics(courseId: number, options?: any) {
        return StatisticsApiFp(this.configuration).statisticsGetLecturersStatistics(courseId, options)(this.fetch, this.basePath);
    }

}
/**
 * SystemApi - fetch parameter creator
 * @export
 */
export const SystemApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemStatus(options: any = {}): FetchArgs {
            const localVarPath = `/api/System/status`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemStatus(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SystemInfo>> {
            const localVarFetchArgs = SystemApiFetchParamCreator(configuration).systemStatus(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemStatus(options?: any) {
            return SystemApiFp(configuration).systemStatus(options)(fetch, basePath);
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public systemStatus(options?: any) {
        return SystemApiFp(this.configuration).systemStatus(options)(this.fetch, this.basePath);
    }

}
/**
 * TasksApi - fetch parameter creator
 * @export
 */
export const TasksApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {AddAnswerForQuestionDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksAddAnswerForQuestion(body?: AddAnswerForQuestionDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/Tasks/addAnswer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddAnswerForQuestionDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {AddTaskQuestionDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksAddQuestionForTask(body?: AddTaskQuestionDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/Tasks/addQuestion`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddTaskQuestionDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} homeworkId
         * @param {CreateTaskViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksAddTask(homeworkId: number, body?: CreateTaskViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'homeworkId' is not null or undefined
            if (homeworkId === null || homeworkId === undefined) {
                throw new RequiredError('homeworkId','Required parameter homeworkId was null or undefined when calling tasksAddTask.');
            }
            const localVarPath = `/api/Tasks/add/{homeworkId}`
                .replace(`{${"homeworkId"}}`, encodeURIComponent(String(homeworkId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateTaskViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksDeleteTask(taskId: number, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling tasksDeleteTask.');
            }
            const localVarPath = `/api/Tasks/delete/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksGetForEditingTask(taskId: number, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling tasksGetForEditingTask.');
            }
            const localVarPath = `/api/Tasks/getForEditing/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksGetQuestionsForTask(taskId: number, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling tasksGetQuestionsForTask.');
            }
            const localVarPath = `/api/Tasks/questions/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksGetTask(taskId: number, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling tasksGetTask.');
            }
            const localVarPath = `/api/Tasks/get/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {CreateTaskViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksUpdateTask(taskId: number, body?: CreateTaskViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling tasksUpdateTask.');
            }
            const localVarPath = `/api/Tasks/update/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? configuration.apiKey("Authorization")
                    : configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateTaskViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @param {AddAnswerForQuestionDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksAddAnswerForQuestion(body?: AddAnswerForQuestionDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksAddAnswerForQuestion(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {AddTaskQuestionDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksAddQuestionForTask(body?: AddTaskQuestionDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksAddQuestionForTask(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} homeworkId
         * @param {CreateTaskViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksAddTask(homeworkId: number, body?: CreateTaskViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksAddTask(homeworkId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksDeleteTask(taskId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksDeleteTask(taskId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksGetForEditingTask(taskId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HomeworkTaskForEditingViewModel> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksGetForEditingTask(taskId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksGetQuestionsForTask(taskId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GetTaskQuestionDto>> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksGetQuestionsForTask(taskId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksGetTask(taskId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HomeworkTaskViewModel> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksGetTask(taskId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @param {number} taskId
         * @param {CreateTaskViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksUpdateTask(taskId: number, body?: CreateTaskViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).tasksUpdateTask(taskId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param {AddAnswerForQuestionDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksAddAnswerForQuestion(body?: AddAnswerForQuestionDto, options?: any) {
            return TasksApiFp(configuration).tasksAddAnswerForQuestion(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {AddTaskQuestionDto} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksAddQuestionForTask(body?: AddTaskQuestionDto, options?: any) {
            return TasksApiFp(configuration).tasksAddQuestionForTask(body, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} homeworkId
         * @param {CreateTaskViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksAddTask(homeworkId: number, body?: CreateTaskViewModel, options?: any) {
            return TasksApiFp(configuration).tasksAddTask(homeworkId, body, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksDeleteTask(taskId: number, options?: any) {
            return TasksApiFp(configuration).tasksDeleteTask(taskId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksGetForEditingTask(taskId: number, options?: any) {
            return TasksApiFp(configuration).tasksGetForEditingTask(taskId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksGetQuestionsForTask(taskId: number, options?: any) {
            return TasksApiFp(configuration).tasksGetQuestionsForTask(taskId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} taskId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksGetTask(taskId: number, options?: any) {
            return TasksApiFp(configuration).tasksGetTask(taskId, options)(fetch, basePath);
        },
        /**
         *
         * @param {number} taskId
         * @param {CreateTaskViewModel} [body]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksUpdateTask(taskId: number, body?: CreateTaskViewModel, options?: any) {
            return TasksApiFp(configuration).tasksUpdateTask(taskId, body, options)(fetch, basePath);
        },
    };
};

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
    /**
     *
     * @param {AddAnswerForQuestionDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksAddAnswerForQuestion(body?: AddAnswerForQuestionDto, options?: any) {
        return TasksApiFp(this.configuration).tasksAddAnswerForQuestion(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {AddTaskQuestionDto} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksAddQuestionForTask(body?: AddTaskQuestionDto, options?: any) {
        return TasksApiFp(this.configuration).tasksAddQuestionForTask(body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} homeworkId
     * @param {CreateTaskViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksAddTask(homeworkId: number, body?: CreateTaskViewModel, options?: any) {
        return TasksApiFp(this.configuration).tasksAddTask(homeworkId, body, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksDeleteTask(taskId: number, options?: any) {
        return TasksApiFp(this.configuration).tasksDeleteTask(taskId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksGetForEditingTask(taskId: number, options?: any) {
        return TasksApiFp(this.configuration).tasksGetForEditingTask(taskId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksGetQuestionsForTask(taskId: number, options?: any) {
        return TasksApiFp(this.configuration).tasksGetQuestionsForTask(taskId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksGetTask(taskId: number, options?: any) {
        return TasksApiFp(this.configuration).tasksGetTask(taskId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @param {number} taskId
     * @param {CreateTaskViewModel} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public tasksUpdateTask(taskId: number, body?: CreateTaskViewModel, options?: any) {
        return TasksApiFp(this.configuration).tasksUpdateTask(taskId, body, options)(this.fetch, this.basePath);
    }

}
