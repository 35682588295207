/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * API Gateway
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration?: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AccountDataDto
 */
export interface AccountDataDto {
    /**
     * 
     * @type {string}
     * @memberof AccountDataDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDataDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDataDto
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDataDto
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDataDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDataDto
     */
    role?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountDataDto
     */
    isExternalAuth?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccountDataDto
     */
    githubId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDataDto
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountDataDto
     */
    companyName?: string;
}

/**
 * 
 * @export
 * @interface AddAnswerForQuestionDto
 */
export interface AddAnswerForQuestionDto {
    /**
     * 
     * @type {number}
     * @memberof AddAnswerForQuestionDto
     */
    questionId?: number;
    /**
     * 
     * @type {string}
     * @memberof AddAnswerForQuestionDto
     */
    answer?: string;
}

/**
 * 
 * @export
 * @interface AddTaskQuestionDto
 */
export interface AddTaskQuestionDto {
    /**
     * 
     * @type {number}
     * @memberof AddTaskQuestionDto
     */
    taskId?: number;
    /**
     * 
     * @type {string}
     * @memberof AddTaskQuestionDto
     */
    text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AddTaskQuestionDto
     */
    isPrivate?: boolean;
}

/**
 * 
 * @export
 * @interface AdvancedCourseStatisticsViewModel
 */
export interface AdvancedCourseStatisticsViewModel {
    /**
     * 
     * @type {CoursePreview}
     * @memberof AdvancedCourseStatisticsViewModel
     */
    course?: CoursePreview;
    /**
     * 
     * @type {Array<HomeworkViewModel>}
     * @memberof AdvancedCourseStatisticsViewModel
     */
    homeworks?: Array<HomeworkViewModel>;
    /**
     * 
     * @type {Array<StatisticsCourseMatesModel>}
     * @memberof AdvancedCourseStatisticsViewModel
     */
    studentStatistics?: Array<StatisticsCourseMatesModel>;
    /**
     * 
     * @type {Array<StatisticsCourseMeasureSolutionModel>}
     * @memberof AdvancedCourseStatisticsViewModel
     */
    averageStudentSolutions?: Array<StatisticsCourseMeasureSolutionModel>;
    /**
     * 
     * @type {Array<StatisticsCourseMeasureSolutionModel>}
     * @memberof AdvancedCourseStatisticsViewModel
     */
    bestStudentSolutions?: Array<StatisticsCourseMeasureSolutionModel>;
}

/**
 * 
 * @export
 * @interface CategorizedNotifications
 */
export interface CategorizedNotifications {
    /**
     * 
     * @type {number}
     * @memberof CategorizedNotifications
     */
    category?: CategorizedNotifications.CategoryEnum;
    /**
     * 
     * @type {Array<NotificationViewModel>}
     * @memberof CategorizedNotifications
     */
    seenNotifications?: Array<NotificationViewModel>;
    /**
     * 
     * @type {Array<NotificationViewModel>}
     * @memberof CategorizedNotifications
     */
    notSeenNotifications?: Array<NotificationViewModel>;
}

/**
 * @export
 * @namespace CategorizedNotifications
 */
export namespace CategorizedNotifications {
    /**
     * @export
     * @enum {string}
     */
    export enum CategoryEnum {
        NUMBER_0 = <any> 0,
        NUMBER_1 = <any> 1,
        NUMBER_2 = <any> 2,
        NUMBER_3 = <any> 3
    }
}

/**
 * 
 * @export
 * @interface CourseEvents
 */
export interface CourseEvents {
    /**
     * 
     * @type {number}
     * @memberof CourseEvents
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseEvents
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseEvents
     */
    groupName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseEvents
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CourseEvents
     */
    newStudentsCount?: number;
}

/**
 * 
 * @export
 * @interface CoursePreview
 */
export interface CoursePreview {
    /**
     * 
     * @type {number}
     * @memberof CoursePreview
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CoursePreview
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursePreview
     */
    groupName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CoursePreview
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CoursePreview
     */
    mentorIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CoursePreview
     */
    taskId?: number;
}

/**
 * 
 * @export
 * @interface CoursePreviewView
 */
export interface CoursePreviewView {
    /**
     * 
     * @type {number}
     * @memberof CoursePreviewView
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CoursePreviewView
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CoursePreviewView
     */
    groupName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CoursePreviewView
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {Array<AccountDataDto>}
     * @memberof CoursePreviewView
     */
    mentors?: Array<AccountDataDto>;
    /**
     * 
     * @type {number}
     * @memberof CoursePreviewView
     */
    taskId?: number;
}

/**
 * 
 * @export
 * @interface CourseViewModel
 */
export interface CourseViewModel {
    /**
     * 
     * @type {number}
     * @memberof CourseViewModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseViewModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CourseViewModel
     */
    groupName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CourseViewModel
     */
    isOpen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CourseViewModel
     */
    isCompleted?: boolean;
    /**
     * 
     * @type {Array<AccountDataDto>}
     * @memberof CourseViewModel
     */
    mentors?: Array<AccountDataDto>;
    /**
     * 
     * @type {Array<AccountDataDto>}
     * @memberof CourseViewModel
     */
    acceptedStudents?: Array<AccountDataDto>;
    /**
     * 
     * @type {Array<AccountDataDto>}
     * @memberof CourseViewModel
     */
    newStudents?: Array<AccountDataDto>;
    /**
     * 
     * @type {Array<HomeworkViewModel>}
     * @memberof CourseViewModel
     */
    homeworks?: Array<HomeworkViewModel>;
}

/**
 * 
 * @export
 * @interface CreateCourseViewModel
 */
export interface CreateCourseViewModel {
    /**
     * 
     * @type {string}
     * @memberof CreateCourseViewModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCourseViewModel
     */
    groupName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateCourseViewModel
     */
    isOpen: boolean;
}

/**
 * 
 * @export
 * @interface CreateGroupViewModel
 */
export interface CreateGroupViewModel {
    /**
     * 
     * @type {string}
     * @memberof CreateGroupViewModel
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateGroupViewModel
     */
    groupMatesIds: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateGroupViewModel
     */
    courseId: number;
}

/**
 * 
 * @export
 * @interface CreateHomeworkViewModel
 */
export interface CreateHomeworkViewModel {
    /**
     * 
     * @type {string}
     * @memberof CreateHomeworkViewModel
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateHomeworkViewModel
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateHomeworkViewModel
     */
    hasDeadline?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CreateHomeworkViewModel
     */
    deadlineDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CreateHomeworkViewModel
     */
    isDeadlineStrict?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CreateHomeworkViewModel
     */
    publicationDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CreateHomeworkViewModel
     */
    isGroupWork?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateHomeworkViewModel
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<CreateTaskViewModel>}
     * @memberof CreateHomeworkViewModel
     */
    tasks?: Array<CreateTaskViewModel>;
}

/**
 * 
 * @export
 * @interface CreateTaskViewModel
 */
export interface CreateTaskViewModel {
    /**
     * 
     * @type {string}
     * @memberof CreateTaskViewModel
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskViewModel
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTaskViewModel
     */
    hasDeadline?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CreateTaskViewModel
     */
    deadlineDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTaskViewModel
     */
    isDeadlineStrict?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CreateTaskViewModel
     */
    publicationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof CreateTaskViewModel
     */
    maxRating: number;
}

/**
 * 
 * @export
 * @interface EditAccountViewModel
 */
export interface EditAccountViewModel {
    /**
     * 
     * @type {string}
     * @memberof EditAccountViewModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EditAccountViewModel
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof EditAccountViewModel
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof EditAccountViewModel
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof EditAccountViewModel
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof EditAccountViewModel
     */
    companyName?: string;
}

/**
 * 
 * @export
 * @interface EditMentorWorkspaceDTO
 */
export interface EditMentorWorkspaceDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof EditMentorWorkspaceDTO
     */
    studentIds?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof EditMentorWorkspaceDTO
     */
    homeworkIds?: Array<number>;
}

/**
 * 
 * @export
 * @interface ExpertDataDTO
 */
export interface ExpertDataDTO {
    /**
     * 
     * @type {string}
     * @memberof ExpertDataDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertDataDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertDataDTO
     */
    surname?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertDataDTO
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertDataDTO
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertDataDTO
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof ExpertDataDTO
     */
    companyName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExpertDataDTO
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExpertDataDTO
     */
    lecturerId?: string;
}

/**
 * 
 * @export
 * @interface GetSolutionModel
 */
export interface GetSolutionModel {
    /**
     * 
     * @type {Date}
     * @memberof GetSolutionModel
     */
    ratingDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof GetSolutionModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetSolutionModel
     */
    githubUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSolutionModel
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof GetSolutionModel
     */
    state?: GetSolutionModel.StateEnum;
    /**
     * 
     * @type {number}
     * @memberof GetSolutionModel
     */
    rating?: number;
    /**
     * 
     * @type {string}
     * @memberof GetSolutionModel
     */
    studentId?: string;
    /**
     * 
     * @type {number}
     * @memberof GetSolutionModel
     */
    taskId?: number;
    /**
     * 
     * @type {Date}
     * @memberof GetSolutionModel
     */
    publicationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetSolutionModel
     */
    lecturerComment?: string;
    /**
     * 
     * @type {Array<AccountDataDto>}
     * @memberof GetSolutionModel
     */
    groupMates?: Array<AccountDataDto>;
    /**
     * 
     * @type {AccountDataDto}
     * @memberof GetSolutionModel
     */
    lecturer?: AccountDataDto;
}

/**
 * @export
 * @namespace GetSolutionModel
 */
export namespace GetSolutionModel {
    /**
     * @export
     * @enum {string}
     */
    export enum StateEnum {
        NUMBER_0 = <any> 0,
        NUMBER_1 = <any> 1,
        NUMBER_2 = <any> 2
    }
}

/**
 * 
 * @export
 * @interface GetTaskQuestionDto
 */
export interface GetTaskQuestionDto {
    /**
     * 
     * @type {number}
     * @memberof GetTaskQuestionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetTaskQuestionDto
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTaskQuestionDto
     */
    text?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetTaskQuestionDto
     */
    isPrivate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetTaskQuestionDto
     */
    answer?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTaskQuestionDto
     */
    lecturerId?: string;
}

/**
 * 
 * @export
 * @interface GithubCredentials
 */
export interface GithubCredentials {
    /**
     * 
     * @type {string}
     * @memberof GithubCredentials
     */
    githubId?: string;
}

/**
 * 
 * @export
 * @interface GroupMateViewModel
 */
export interface GroupMateViewModel {
    /**
     * 
     * @type {string}
     * @memberof GroupMateViewModel
     */
    studentId?: string;
}

/**
 * 
 * @export
 * @interface GroupViewModel
 */
export interface GroupViewModel {
    /**
     * 
     * @type {number}
     * @memberof GroupViewModel
     */
    id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GroupViewModel
     */
    studentsIds?: Array<string>;
}

/**
 * 
 * @export
 * @interface HomeworkSolutionsStats
 */
export interface HomeworkSolutionsStats {
    /**
     * 
     * @type {string}
     * @memberof HomeworkSolutionsStats
     */
    homeworkTitle?: string;
    /**
     * 
     * @type {Array<TaskSolutionsStats>}
     * @memberof HomeworkSolutionsStats
     */
    statsForTasks?: Array<TaskSolutionsStats>;
}

/**
 * 
 * @export
 * @interface HomeworkTaskForEditingViewModel
 */
export interface HomeworkTaskForEditingViewModel {
    /**
     * 
     * @type {HomeworkTaskViewModel}
     * @memberof HomeworkTaskForEditingViewModel
     */
    task?: HomeworkTaskViewModel;
    /**
     * 
     * @type {HomeworkViewModel}
     * @memberof HomeworkTaskForEditingViewModel
     */
    homework?: HomeworkViewModel;
}

/**
 * 
 * @export
 * @interface HomeworkTaskViewModel
 */
export interface HomeworkTaskViewModel {
    /**
     * 
     * @type {number}
     * @memberof HomeworkTaskViewModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof HomeworkTaskViewModel
     */
    title?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof HomeworkTaskViewModel
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof HomeworkTaskViewModel
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof HomeworkTaskViewModel
     */
    maxRating?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HomeworkTaskViewModel
     */
    hasDeadline?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof HomeworkTaskViewModel
     */
    deadlineDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof HomeworkTaskViewModel
     */
    isDeadlineStrict?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HomeworkTaskViewModel
     */
    canSendSolution?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof HomeworkTaskViewModel
     */
    publicationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof HomeworkTaskViewModel
     */
    homeworkId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HomeworkTaskViewModel
     */
    isGroupWork?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HomeworkTaskViewModel
     */
    isDeferred?: boolean;
}

/**
 * 
 * @export
 * @interface HomeworkUserTaskSolutions
 */
export interface HomeworkUserTaskSolutions {
    /**
     * 
     * @type {string}
     * @memberof HomeworkUserTaskSolutions
     */
    homeworkTitle?: string;
    /**
     * 
     * @type {Array<UserTaskSolutions2>}
     * @memberof HomeworkUserTaskSolutions
     */
    studentSolutions?: Array<UserTaskSolutions2>;
}

/**
 * 
 * @export
 * @interface HomeworkViewModel
 */
export interface HomeworkViewModel {
    /**
     * 
     * @type {number}
     * @memberof HomeworkViewModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof HomeworkViewModel
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof HomeworkViewModel
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HomeworkViewModel
     */
    hasDeadline?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof HomeworkViewModel
     */
    deadlineDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof HomeworkViewModel
     */
    isDeadlineStrict?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof HomeworkViewModel
     */
    publicationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof HomeworkViewModel
     */
    courseId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HomeworkViewModel
     */
    isDeferred?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HomeworkViewModel
     */
    isGroupWork?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof HomeworkViewModel
     */
    tags?: Array<string>;
    /**
     * 
     * @type {Array<HomeworkTaskViewModel>}
     * @memberof HomeworkViewModel
     */
    tasks?: Array<HomeworkTaskViewModel>;
}

/**
 * 
 * @export
 * @interface HomeworksGroupSolutionStats
 */
export interface HomeworksGroupSolutionStats {
    /**
     * 
     * @type {string}
     * @memberof HomeworksGroupSolutionStats
     */
    groupTitle?: string;
    /**
     * 
     * @type {Array<HomeworkSolutionsStats>}
     * @memberof HomeworksGroupSolutionStats
     */
    statsForHomeworks?: Array<HomeworkSolutionsStats>;
}

/**
 * 
 * @export
 * @interface HomeworksGroupUserTaskSolutions
 */
export interface HomeworksGroupUserTaskSolutions {
    /**
     * 
     * @type {string}
     * @memberof HomeworksGroupUserTaskSolutions
     */
    groupTitle?: string;
    /**
     * 
     * @type {Array<HomeworkUserTaskSolutions>}
     * @memberof HomeworksGroupUserTaskSolutions
     */
    homeworkSolutions?: Array<HomeworkUserTaskSolutions>;
}

/**
 * 
 * @export
 * @interface InviteExpertViewModel
 */
export interface InviteExpertViewModel {
    /**
     * 
     * @type {string}
     * @memberof InviteExpertViewModel
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteExpertViewModel
     */
    userEmail?: string;
    /**
     * 
     * @type {number}
     * @memberof InviteExpertViewModel
     */
    courseId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof InviteExpertViewModel
     */
    studentIds?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof InviteExpertViewModel
     */
    homeworkIds?: Array<number>;
}

/**
 * 
 * @export
 * @interface InviteLecturerViewModel
 */
export interface InviteLecturerViewModel {
    /**
     * 
     * @type {string}
     * @memberof InviteLecturerViewModel
     */
    email: string;
}

/**
 * 
 * @export
 * @interface LoginViewModel
 */
export interface LoginViewModel {
    /**
     * 
     * @type {string}
     * @memberof LoginViewModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LoginViewModel
     */
    password: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginViewModel
     */
    rememberMe: boolean;
}

/**
 * 
 * @export
 * @interface NotificationViewModel
 */
export interface NotificationViewModel {
    /**
     * 
     * @type {number}
     * @memberof NotificationViewModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationViewModel
     */
    sender?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationViewModel
     */
    owner?: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationViewModel
     */
    category?: NotificationViewModel.CategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationViewModel
     */
    body?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationViewModel
     */
    hasSeen?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof NotificationViewModel
     */
    date?: Date;
}

/**
 * @export
 * @namespace NotificationViewModel
 */
export namespace NotificationViewModel {
    /**
     * @export
     * @enum {string}
     */
    export enum CategoryEnum {
        NUMBER_0 = <any> 0,
        NUMBER_1 = <any> 1,
        NUMBER_2 = <any> 2,
        NUMBER_3 = <any> 3
    }
}

/**
 * 
 * @export
 * @interface NotificationsSettingDto
 */
export interface NotificationsSettingDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationsSettingDto
     */
    category?: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsSettingDto
     */
    isEnabled?: boolean;
}

/**
 * 
 * @export
 * @interface RateSolutionModel
 */
export interface RateSolutionModel {
    /**
     * 
     * @type {number}
     * @memberof RateSolutionModel
     */
    rating?: number;
    /**
     * 
     * @type {string}
     * @memberof RateSolutionModel
     */
    lecturerComment?: string;
}

/**
 * 
 * @export
 * @interface RegisterExpertViewModel
 */
export interface RegisterExpertViewModel {
    /**
     * 
     * @type {string}
     * @memberof RegisterExpertViewModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterExpertViewModel
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterExpertViewModel
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterExpertViewModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterExpertViewModel
     */
    bio?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterExpertViewModel
     */
    companyName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RegisterExpertViewModel
     */
    tags?: Array<string>;
}

/**
 * 
 * @export
 * @interface RegisterViewModel
 */
export interface RegisterViewModel {
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterViewModel
     */
    passwordConfirm: string;
}

/**
 * 
 * @export
 * @interface RequestPasswordRecoveryViewModel
 */
export interface RequestPasswordRecoveryViewModel {
    /**
     * 
     * @type {string}
     * @memberof RequestPasswordRecoveryViewModel
     */
    email: string;
}

/**
 * 
 * @export
 * @interface ResetPasswordViewModel
 */
export interface ResetPasswordViewModel {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordViewModel
     */
    passwordConfirm: string;
}

/**
 * 
 * @export
 * @interface Result
 */
export interface Result {
    /**
     * 
     * @type {boolean}
     * @memberof Result
     */
    succeeded?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Result
     */
    errors?: Array<string>;
}

/**
 * 
 * @export
 * @interface ResultBoolean
 */
export interface ResultBoolean {
    /**
     * 
     * @type {boolean}
     * @memberof ResultBoolean
     */
    value?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResultBoolean
     */
    succeeded?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResultBoolean
     */
    errors?: Array<string>;
}

/**
 * 
 * @export
 * @interface ResultTokenCredentials
 */
export interface ResultTokenCredentials {
    /**
     * 
     * @type {TokenCredentials}
     * @memberof ResultTokenCredentials
     */
    value?: TokenCredentials;
    /**
     * 
     * @type {boolean}
     * @memberof ResultTokenCredentials
     */
    succeeded?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResultTokenCredentials
     */
    errors?: Array<string>;
}

/**
 * 
 * @export
 * @interface Solution
 */
export interface Solution {
    /**
     * 
     * @type {number}
     * @memberof Solution
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    githubUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof Solution
     */
    state?: Solution.StateEnum;
    /**
     * 
     * @type {number}
     * @memberof Solution
     */
    rating?: number;
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    studentId?: string;
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    lecturerId?: string;
    /**
     * 
     * @type {number}
     * @memberof Solution
     */
    groupId?: number;
    /**
     * 
     * @type {number}
     * @memberof Solution
     */
    taskId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Solution
     */
    publicationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Solution
     */
    ratingDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Solution
     */
    lecturerComment?: string;
}

/**
 * @export
 * @namespace Solution
 */
export namespace Solution {
    /**
     * @export
     * @enum {string}
     */
    export enum StateEnum {
        NUMBER_0 = <any> 0,
        NUMBER_1 = <any> 1,
        NUMBER_2 = <any> 2
    }
}

/**
 * 
 * @export
 * @interface SolutionActualityDto
 */
export interface SolutionActualityDto {
    /**
     * 
     * @type {SolutionActualityPart}
     * @memberof SolutionActualityDto
     */
    commitsActuality?: SolutionActualityPart;
    /**
     * 
     * @type {SolutionActualityPart}
     * @memberof SolutionActualityDto
     */
    testsActuality?: SolutionActualityPart;
}

/**
 * 
 * @export
 * @interface SolutionActualityPart
 */
export interface SolutionActualityPart {
    /**
     * 
     * @type {boolean}
     * @memberof SolutionActualityPart
     */
    isActual?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SolutionActualityPart
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof SolutionActualityPart
     */
    additionalData?: string;
}

/**
 * 
 * @export
 * @interface SolutionPreviewView
 */
export interface SolutionPreviewView {
    /**
     * 
     * @type {number}
     * @memberof SolutionPreviewView
     */
    solutionId?: number;
    /**
     * 
     * @type {AccountDataDto}
     * @memberof SolutionPreviewView
     */
    student?: AccountDataDto;
    /**
     * 
     * @type {string}
     * @memberof SolutionPreviewView
     */
    courseTitle?: string;
    /**
     * 
     * @type {number}
     * @memberof SolutionPreviewView
     */
    courseId?: number;
    /**
     * 
     * @type {string}
     * @memberof SolutionPreviewView
     */
    homeworkTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof SolutionPreviewView
     */
    taskTitle?: string;
    /**
     * 
     * @type {number}
     * @memberof SolutionPreviewView
     */
    taskId?: number;
    /**
     * 
     * @type {Date}
     * @memberof SolutionPreviewView
     */
    publicationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof SolutionPreviewView
     */
    groupId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SolutionPreviewView
     */
    isFirstTry?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SolutionPreviewView
     */
    sentAfterDeadline?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SolutionPreviewView
     */
    isCourseCompleted?: boolean;
}

/**
 * 
 * @export
 * @interface SolutionViewModel
 */
export interface SolutionViewModel {
    /**
     * 
     * @type {string}
     * @memberof SolutionViewModel
     */
    githubUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof SolutionViewModel
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof SolutionViewModel
     */
    studentId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SolutionViewModel
     */
    groupMateIds?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof SolutionViewModel
     */
    publicationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof SolutionViewModel
     */
    lecturerComment?: string;
    /**
     * 
     * @type {number}
     * @memberof SolutionViewModel
     */
    rating?: number;
    /**
     * 
     * @type {Date}
     * @memberof SolutionViewModel
     */
    ratingDate?: Date;
}

/**
 * 
 * @export
 * @interface StatisticsCourseHomeworksModel
 */
export interface StatisticsCourseHomeworksModel {
    /**
     * 
     * @type {number}
     * @memberof StatisticsCourseHomeworksModel
     */
    id?: number;
    /**
     * 
     * @type {Array<StatisticsCourseTasksModel>}
     * @memberof StatisticsCourseHomeworksModel
     */
    tasks?: Array<StatisticsCourseTasksModel>;
}

/**
 * 
 * @export
 * @interface StatisticsCourseMatesModel
 */
export interface StatisticsCourseMatesModel {
    /**
     * 
     * @type {string}
     * @memberof StatisticsCourseMatesModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StatisticsCourseMatesModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StatisticsCourseMatesModel
     */
    surname?: string;
    /**
     * 
     * @type {Array<StatisticsCourseHomeworksModel>}
     * @memberof StatisticsCourseMatesModel
     */
    homeworks?: Array<StatisticsCourseHomeworksModel>;
}

/**
 * 
 * @export
 * @interface StatisticsCourseMeasureSolutionModel
 */
export interface StatisticsCourseMeasureSolutionModel {
    /**
     * 
     * @type {number}
     * @memberof StatisticsCourseMeasureSolutionModel
     */
    rating?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsCourseMeasureSolutionModel
     */
    taskId?: number;
    /**
     * 
     * @type {Date}
     * @memberof StatisticsCourseMeasureSolutionModel
     */
    publicationDate?: Date;
}

/**
 * 
 * @export
 * @interface StatisticsCourseTasksModel
 */
export interface StatisticsCourseTasksModel {
    /**
     * 
     * @type {number}
     * @memberof StatisticsCourseTasksModel
     */
    id?: number;
    /**
     * 
     * @type {Array<Solution>}
     * @memberof StatisticsCourseTasksModel
     */
    solution?: Array<Solution>;
}

/**
 * 
 * @export
 * @interface StatisticsLecturersModel
 */
export interface StatisticsLecturersModel {
    /**
     * 
     * @type {AccountDataDto}
     * @memberof StatisticsLecturersModel
     */
    lecturer?: AccountDataDto;
    /**
     * 
     * @type {number}
     * @memberof StatisticsLecturersModel
     */
    numberOfCheckedSolutions?: number;
    /**
     * 
     * @type {number}
     * @memberof StatisticsLecturersModel
     */
    numberOfCheckedUniqueSolutions?: number;
}

/**
 * 
 * @export
 * @interface SystemInfo
 */
export interface SystemInfo {
    /**
     * 
     * @type {string}
     * @memberof SystemInfo
     */
    service?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SystemInfo
     */
    isAvailable?: boolean;
}

/**
 * 
 * @export
 * @interface TaskDeadlineDto
 */
export interface TaskDeadlineDto {
    /**
     * 
     * @type {number}
     * @memberof TaskDeadlineDto
     */
    taskId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskDeadlineDto
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof TaskDeadlineDto
     */
    taskTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDeadlineDto
     */
    courseTitle?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskDeadlineDto
     */
    courseId?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskDeadlineDto
     */
    homeworkId?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskDeadlineDto
     */
    maxRating?: number;
    /**
     * 
     * @type {Date}
     * @memberof TaskDeadlineDto
     */
    publicationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TaskDeadlineDto
     */
    deadlineDate?: Date;
}

/**
 * 
 * @export
 * @interface TaskDeadlineView
 */
export interface TaskDeadlineView {
    /**
     * 
     * @type {TaskDeadlineDto}
     * @memberof TaskDeadlineView
     */
    deadline?: TaskDeadlineDto;
    /**
     * 
     * @type {number}
     * @memberof TaskDeadlineView
     */
    solutionState?: TaskDeadlineView.SolutionStateEnum;
    /**
     * 
     * @type {number}
     * @memberof TaskDeadlineView
     */
    rating?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskDeadlineView
     */
    maxRating?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TaskDeadlineView
     */
    deadlinePast?: boolean;
}

/**
 * @export
 * @namespace TaskDeadlineView
 */
export namespace TaskDeadlineView {
    /**
     * @export
     * @enum {string}
     */
    export enum SolutionStateEnum {
        NUMBER_0 = <any> 0,
        NUMBER_1 = <any> 1,
        NUMBER_2 = <any> 2
    }
}

/**
 * 
 * @export
 * @interface TaskSolutionStatisticsPageData
 */
export interface TaskSolutionStatisticsPageData {
    /**
     * 
     * @type {Array<TaskSolutions>}
     * @memberof TaskSolutionStatisticsPageData
     */
    taskSolutions?: Array<TaskSolutions>;
    /**
     * 
     * @type {number}
     * @memberof TaskSolutionStatisticsPageData
     */
    courseId?: number;
    /**
     * 
     * @type {Array<HomeworksGroupSolutionStats>}
     * @memberof TaskSolutionStatisticsPageData
     */
    statsForTasks?: Array<HomeworksGroupSolutionStats>;
}

/**
 * 
 * @export
 * @interface TaskSolutions
 */
export interface TaskSolutions {
    /**
     * 
     * @type {number}
     * @memberof TaskSolutions
     */
    taskId?: number;
    /**
     * 
     * @type {Array<UserTaskSolutions>}
     * @memberof TaskSolutions
     */
    studentSolutions?: Array<UserTaskSolutions>;
}

/**
 * 
 * @export
 * @interface TaskSolutionsStats
 */
export interface TaskSolutionsStats {
    /**
     * 
     * @type {number}
     * @memberof TaskSolutionsStats
     */
    taskId?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskSolutionsStats
     */
    countUnratedSolutions?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskSolutionsStats
     */
    title?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TaskSolutionsStats
     */
    tags?: Array<string>;
}

/**
 * 
 * @export
 * @interface TokenCredentials
 */
export interface TokenCredentials {
    /**
     * 
     * @type {string}
     * @memberof TokenCredentials
     */
    accessToken?: string;
}

/**
 * 
 * @export
 * @interface UnratedSolutionPreviews
 */
export interface UnratedSolutionPreviews {
    /**
     * 
     * @type {Array<SolutionPreviewView>}
     * @memberof UnratedSolutionPreviews
     */
    unratedSolutions?: Array<SolutionPreviewView>;
}

/**
 * 
 * @export
 * @interface UpdateCourseViewModel
 */
export interface UpdateCourseViewModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseViewModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCourseViewModel
     */
    groupName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCourseViewModel
     */
    isOpen: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCourseViewModel
     */
    isCompleted?: boolean;
}

/**
 * 
 * @export
 * @interface UpdateExpertTagsDTO
 */
export interface UpdateExpertTagsDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateExpertTagsDTO
     */
    expertId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateExpertTagsDTO
     */
    tags?: Array<string>;
}

/**
 * 
 * @export
 * @interface UpdateGroupViewModel
 */
export interface UpdateGroupViewModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateGroupViewModel
     */
    name?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateGroupViewModel
     */
    tasks?: Array<number>;
    /**
     * 
     * @type {Array<GroupMateViewModel>}
     * @memberof UpdateGroupViewModel
     */
    groupMates?: Array<GroupMateViewModel>;
}

/**
 * 
 * @export
 * @interface UrlDto
 */
export interface UrlDto {
    /**
     * 
     * @type {string}
     * @memberof UrlDto
     */
    url?: string;
}

/**
 * 
 * @export
 * @interface UserDataDto
 */
export interface UserDataDto {
    /**
     * 
     * @type {AccountDataDto}
     * @memberof UserDataDto
     */
    userData?: AccountDataDto;
    /**
     * 
     * @type {Array<CourseEvents>}
     * @memberof UserDataDto
     */
    courseEvents?: Array<CourseEvents>;
    /**
     * 
     * @type {Array<TaskDeadlineView>}
     * @memberof UserDataDto
     */
    taskDeadlines?: Array<TaskDeadlineView>;
}

/**
 * 
 * @export
 * @interface UserTaskSolutions
 */
export interface UserTaskSolutions {
    /**
     * 
     * @type {Array<GetSolutionModel>}
     * @memberof UserTaskSolutions
     */
    solutions?: Array<GetSolutionModel>;
    /**
     * 
     * @type {AccountDataDto}
     * @memberof UserTaskSolutions
     */
    user?: AccountDataDto;
}

/**
 * 
 * @export
 * @interface UserTaskSolutions2
 */
export interface UserTaskSolutions2 {
    /**
     * 
     * @type {number}
     * @memberof UserTaskSolutions2
     */
    maxRating?: number;
    /**
     * 
     * @type {string}
     * @memberof UserTaskSolutions2
     */
    title?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserTaskSolutions2
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserTaskSolutions2
     */
    taskId?: string;
    /**
     * 
     * @type {Array<GetSolutionModel>}
     * @memberof UserTaskSolutions2
     */
    solutions?: Array<GetSolutionModel>;
}

/**
 * 
 * @export
 * @interface UserTaskSolutionsPageData
 */
export interface UserTaskSolutionsPageData {
    /**
     * 
     * @type {number}
     * @memberof UserTaskSolutionsPageData
     */
    courseId?: number;
    /**
     * 
     * @type {Array<AccountDataDto>}
     * @memberof UserTaskSolutionsPageData
     */
    courseMates?: Array<AccountDataDto>;
    /**
     * 
     * @type {Array<HomeworksGroupUserTaskSolutions>}
     * @memberof UserTaskSolutionsPageData
     */
    taskSolutions?: Array<HomeworksGroupUserTaskSolutions>;
}

/**
 * 
 * @export
 * @interface WorkspaceViewModel
 */
export interface WorkspaceViewModel {
    /**
     * 
     * @type {Array<AccountDataDto>}
     * @memberof WorkspaceViewModel
     */
    students?: Array<AccountDataDto>;
    /**
     * 
     * @type {Array<HomeworkViewModel>}
     * @memberof WorkspaceViewModel
     */
    homeworks?: Array<HomeworkViewModel>;
}


/**
 * AccountApi - fetch parameter creator
 * @export
 */
export const AccountApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EditAccountViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountEditPut(model?: EditAccountViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/edit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EditAccountViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(model || {}) : (model || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountGetAllStudentsGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/getAllStudents`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountGetUserDataByUserIdGet(userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling apiAccountGetUserDataByUserIdGet.');
            }
            const localVarPath = `/api/Account/getUserData/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountGetUserDataGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/getUserData`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountGithubAuthorizePost(code?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/github/authorize`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UrlDto} [urlDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountGithubUrlPost(urlDto?: UrlDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/github/url`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UrlDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(urlDto || {}) : (urlDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InviteLecturerViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountInviteNewLecturerPost(model?: InviteLecturerViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/inviteNewLecturer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InviteLecturerViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(model || {}) : (model || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLoginPost(model?: LoginViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LoginViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(model || {}) : (model || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRefreshTokenGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/refreshToken`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRegisterPost(model?: RegisterViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/register`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RegisterViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(model || {}) : (model || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RequestPasswordRecoveryViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRequestPasswordRecoveryPost(model?: RequestPasswordRecoveryViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/requestPasswordRecovery`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RequestPasswordRecoveryViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(model || {}) : (model || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountResetPasswordPost(model?: ResetPasswordViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Account/resetPassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ResetPasswordViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(model || {}) : (model || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EditAccountViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountEditPut(model?: EditAccountViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).apiAccountEditPut(model, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountGetAllStudentsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AccountDataDto>> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).apiAccountGetAllStudentsGet(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountGetUserDataByUserIdGet(userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AccountDataDto> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).apiAccountGetUserDataByUserIdGet(userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountGetUserDataGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDataDto> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).apiAccountGetUserDataGet(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountGithubAuthorizePost(code?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GithubCredentials> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).apiAccountGithubAuthorizePost(code, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UrlDto} [urlDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountGithubUrlPost(urlDto?: UrlDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UrlDto> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).apiAccountGithubUrlPost(urlDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {InviteLecturerViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountInviteNewLecturerPost(model?: InviteLecturerViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).apiAccountInviteNewLecturerPost(model, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {LoginViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLoginPost(model?: LoginViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResultTokenCredentials> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).apiAccountLoginPost(model, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRefreshTokenGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResultTokenCredentials> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).apiAccountRefreshTokenGet(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RegisterViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRegisterPost(model?: RegisterViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResultTokenCredentials> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).apiAccountRegisterPost(model, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RequestPasswordRecoveryViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRequestPasswordRecoveryPost(model?: RequestPasswordRecoveryViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).apiAccountRequestPasswordRecoveryPost(model, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {ResetPasswordViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountResetPasswordPost(model?: ResetPasswordViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = AccountApiFetchParamCreator(configuration).apiAccountResetPasswordPost(model, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {EditAccountViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountEditPut(model?: EditAccountViewModel, options?: any) {
            return AccountApiFp(configuration).apiAccountEditPut(model, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountGetAllStudentsGet(options?: any) {
            return AccountApiFp(configuration).apiAccountGetAllStudentsGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountGetUserDataByUserIdGet(userId: string, options?: any) {
            return AccountApiFp(configuration).apiAccountGetUserDataByUserIdGet(userId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountGetUserDataGet(options?: any) {
            return AccountApiFp(configuration).apiAccountGetUserDataGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountGithubAuthorizePost(code?: string, options?: any) {
            return AccountApiFp(configuration).apiAccountGithubAuthorizePost(code, options)(fetch, basePath);
        },
        /**
         * 
         * @param {UrlDto} [urlDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountGithubUrlPost(urlDto?: UrlDto, options?: any) {
            return AccountApiFp(configuration).apiAccountGithubUrlPost(urlDto, options)(fetch, basePath);
        },
        /**
         * 
         * @param {InviteLecturerViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountInviteNewLecturerPost(model?: InviteLecturerViewModel, options?: any) {
            return AccountApiFp(configuration).apiAccountInviteNewLecturerPost(model, options)(fetch, basePath);
        },
        /**
         * 
         * @param {LoginViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountLoginPost(model?: LoginViewModel, options?: any) {
            return AccountApiFp(configuration).apiAccountLoginPost(model, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRefreshTokenGet(options?: any) {
            return AccountApiFp(configuration).apiAccountRefreshTokenGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {RegisterViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRegisterPost(model?: RegisterViewModel, options?: any) {
            return AccountApiFp(configuration).apiAccountRegisterPost(model, options)(fetch, basePath);
        },
        /**
         * 
         * @param {RequestPasswordRecoveryViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountRequestPasswordRecoveryPost(model?: RequestPasswordRecoveryViewModel, options?: any) {
            return AccountApiFp(configuration).apiAccountRequestPasswordRecoveryPost(model, options)(fetch, basePath);
        },
        /**
         * 
         * @param {ResetPasswordViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountResetPasswordPost(model?: ResetPasswordViewModel, options?: any) {
            return AccountApiFp(configuration).apiAccountResetPasswordPost(model, options)(fetch, basePath);
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {EditAccountViewModel} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountEditPut(model?: EditAccountViewModel, options?: any) {
        return AccountApiFp(this.configuration).apiAccountEditPut(model, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountGetAllStudentsGet(options?: any) {
        return AccountApiFp(this.configuration).apiAccountGetAllStudentsGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountGetUserDataByUserIdGet(userId: string, options?: any) {
        return AccountApiFp(this.configuration).apiAccountGetUserDataByUserIdGet(userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountGetUserDataGet(options?: any) {
        return AccountApiFp(this.configuration).apiAccountGetUserDataGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountGithubAuthorizePost(code?: string, options?: any) {
        return AccountApiFp(this.configuration).apiAccountGithubAuthorizePost(code, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {UrlDto} [urlDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountGithubUrlPost(urlDto?: UrlDto, options?: any) {
        return AccountApiFp(this.configuration).apiAccountGithubUrlPost(urlDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {InviteLecturerViewModel} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountInviteNewLecturerPost(model?: InviteLecturerViewModel, options?: any) {
        return AccountApiFp(this.configuration).apiAccountInviteNewLecturerPost(model, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {LoginViewModel} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountLoginPost(model?: LoginViewModel, options?: any) {
        return AccountApiFp(this.configuration).apiAccountLoginPost(model, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountRefreshTokenGet(options?: any) {
        return AccountApiFp(this.configuration).apiAccountRefreshTokenGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {RegisterViewModel} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountRegisterPost(model?: RegisterViewModel, options?: any) {
        return AccountApiFp(this.configuration).apiAccountRegisterPost(model, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {RequestPasswordRecoveryViewModel} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountRequestPasswordRecoveryPost(model?: RequestPasswordRecoveryViewModel, options?: any) {
        return AccountApiFp(this.configuration).apiAccountRequestPasswordRecoveryPost(model, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {ResetPasswordViewModel} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountResetPasswordPost(model?: ResetPasswordViewModel, options?: any) {
        return AccountApiFp(this.configuration).apiAccountResetPasswordPost(model, options)(this.fetch, this.basePath);
    }

}

/**
 * CourseGroupsApi - fetch parameter creator
 * @export
 */
export const CourseGroupsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} courseId 
         * @param {number} groupId 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdAddStudentInGroupByGroupIdPost(courseId: number, groupId: number, userId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCourseGroupsByCourseIdAddStudentInGroupByGroupIdPost.');
            }
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiCourseGroupsByCourseIdAddStudentInGroupByGroupIdPost.');
            }
            const localVarPath = `/api/CourseGroups/{courseId}/addStudentInGroup/{groupId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {CreateGroupViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdCreatePost(courseId: number, model?: CreateGroupViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCourseGroupsByCourseIdCreatePost.');
            }
            const localVarPath = `/api/CourseGroups/{courseId}/create`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateGroupViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(model || {}) : (model || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdDeleteByGroupIdDelete(courseId: number, groupId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCourseGroupsByCourseIdDeleteByGroupIdDelete.');
            }
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiCourseGroupsByCourseIdDeleteByGroupIdDelete.');
            }
            const localVarPath = `/api/CourseGroups/{courseId}/delete/{groupId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdGetAllGet(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCourseGroupsByCourseIdGetAllGet.');
            }
            const localVarPath = `/api/CourseGroups/{courseId}/getAll`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdGetGet(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCourseGroupsByCourseIdGetGet.');
            }
            const localVarPath = `/api/CourseGroups/{courseId}/get`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {number} groupId 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdRemoveStudentFromGroupByGroupIdPost(courseId: number, groupId: number, userId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCourseGroupsByCourseIdRemoveStudentFromGroupByGroupIdPost.');
            }
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiCourseGroupsByCourseIdRemoveStudentFromGroupByGroupIdPost.');
            }
            const localVarPath = `/api/CourseGroups/{courseId}/removeStudentFromGroup/{groupId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {number} groupId 
         * @param {UpdateGroupViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdUpdateByGroupIdPost(courseId: number, groupId: number, model?: UpdateGroupViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCourseGroupsByCourseIdUpdateByGroupIdPost.');
            }
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiCourseGroupsByCourseIdUpdateByGroupIdPost.');
            }
            const localVarPath = `/api/CourseGroups/{courseId}/update/{groupId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateGroupViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(model || {}) : (model || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsGetByGroupIdGet(groupId: number, options: any = {}): FetchArgs {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiCourseGroupsGetByGroupIdGet.');
            }
            const localVarPath = `/api/CourseGroups/get/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsGetTasksByGroupIdGet(groupId: number, options: any = {}): FetchArgs {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling apiCourseGroupsGetTasksByGroupIdGet.');
            }
            const localVarPath = `/api/CourseGroups/getTasks/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CourseGroupsApi - functional programming interface
 * @export
 */
export const CourseGroupsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} courseId 
         * @param {number} groupId 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdAddStudentInGroupByGroupIdPost(courseId: number, groupId: number, userId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).apiCourseGroupsByCourseIdAddStudentInGroupByGroupIdPost(courseId, groupId, userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {CreateGroupViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdCreatePost(courseId: number, model?: CreateGroupViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).apiCourseGroupsByCourseIdCreatePost(courseId, model, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdDeleteByGroupIdDelete(courseId: number, groupId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).apiCourseGroupsByCourseIdDeleteByGroupIdDelete(courseId, groupId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdGetAllGet(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GroupViewModel>> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).apiCourseGroupsByCourseIdGetAllGet(courseId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdGetGet(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GroupViewModel> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).apiCourseGroupsByCourseIdGetGet(courseId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {number} groupId 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdRemoveStudentFromGroupByGroupIdPost(courseId: number, groupId: number, userId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).apiCourseGroupsByCourseIdRemoveStudentFromGroupByGroupIdPost(courseId, groupId, userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {number} groupId 
         * @param {UpdateGroupViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdUpdateByGroupIdPost(courseId: number, groupId: number, model?: UpdateGroupViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).apiCourseGroupsByCourseIdUpdateByGroupIdPost(courseId, groupId, model, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsGetByGroupIdGet(groupId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GroupViewModel> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).apiCourseGroupsGetByGroupIdGet(groupId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsGetTasksByGroupIdGet(groupId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<number>> {
            const localVarFetchArgs = CourseGroupsApiFetchParamCreator(configuration).apiCourseGroupsGetTasksByGroupIdGet(groupId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CourseGroupsApi - factory interface
 * @export
 */
export const CourseGroupsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} courseId 
         * @param {number} groupId 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdAddStudentInGroupByGroupIdPost(courseId: number, groupId: number, userId?: string, options?: any) {
            return CourseGroupsApiFp(configuration).apiCourseGroupsByCourseIdAddStudentInGroupByGroupIdPost(courseId, groupId, userId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {CreateGroupViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdCreatePost(courseId: number, model?: CreateGroupViewModel, options?: any) {
            return CourseGroupsApiFp(configuration).apiCourseGroupsByCourseIdCreatePost(courseId, model, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdDeleteByGroupIdDelete(courseId: number, groupId: number, options?: any) {
            return CourseGroupsApiFp(configuration).apiCourseGroupsByCourseIdDeleteByGroupIdDelete(courseId, groupId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdGetAllGet(courseId: number, options?: any) {
            return CourseGroupsApiFp(configuration).apiCourseGroupsByCourseIdGetAllGet(courseId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdGetGet(courseId: number, options?: any) {
            return CourseGroupsApiFp(configuration).apiCourseGroupsByCourseIdGetGet(courseId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {number} groupId 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdRemoveStudentFromGroupByGroupIdPost(courseId: number, groupId: number, userId?: string, options?: any) {
            return CourseGroupsApiFp(configuration).apiCourseGroupsByCourseIdRemoveStudentFromGroupByGroupIdPost(courseId, groupId, userId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {number} groupId 
         * @param {UpdateGroupViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsByCourseIdUpdateByGroupIdPost(courseId: number, groupId: number, model?: UpdateGroupViewModel, options?: any) {
            return CourseGroupsApiFp(configuration).apiCourseGroupsByCourseIdUpdateByGroupIdPost(courseId, groupId, model, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsGetByGroupIdGet(groupId: number, options?: any) {
            return CourseGroupsApiFp(configuration).apiCourseGroupsGetByGroupIdGet(groupId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCourseGroupsGetTasksByGroupIdGet(groupId: number, options?: any) {
            return CourseGroupsApiFp(configuration).apiCourseGroupsGetTasksByGroupIdGet(groupId, options)(fetch, basePath);
        },
    };
};

/**
 * CourseGroupsApi - object-oriented interface
 * @export
 * @class CourseGroupsApi
 * @extends {BaseAPI}
 */
export class CourseGroupsApi extends BaseAPI {
    /**
     * 
     * @param {number} courseId 
     * @param {number} groupId 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public apiCourseGroupsByCourseIdAddStudentInGroupByGroupIdPost(courseId: number, groupId: number, userId?: string, options?: any) {
        return CourseGroupsApiFp(this.configuration).apiCourseGroupsByCourseIdAddStudentInGroupByGroupIdPost(courseId, groupId, userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {CreateGroupViewModel} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public apiCourseGroupsByCourseIdCreatePost(courseId: number, model?: CreateGroupViewModel, options?: any) {
        return CourseGroupsApiFp(this.configuration).apiCourseGroupsByCourseIdCreatePost(courseId, model, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {number} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public apiCourseGroupsByCourseIdDeleteByGroupIdDelete(courseId: number, groupId: number, options?: any) {
        return CourseGroupsApiFp(this.configuration).apiCourseGroupsByCourseIdDeleteByGroupIdDelete(courseId, groupId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public apiCourseGroupsByCourseIdGetAllGet(courseId: number, options?: any) {
        return CourseGroupsApiFp(this.configuration).apiCourseGroupsByCourseIdGetAllGet(courseId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public apiCourseGroupsByCourseIdGetGet(courseId: number, options?: any) {
        return CourseGroupsApiFp(this.configuration).apiCourseGroupsByCourseIdGetGet(courseId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {number} groupId 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public apiCourseGroupsByCourseIdRemoveStudentFromGroupByGroupIdPost(courseId: number, groupId: number, userId?: string, options?: any) {
        return CourseGroupsApiFp(this.configuration).apiCourseGroupsByCourseIdRemoveStudentFromGroupByGroupIdPost(courseId, groupId, userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {number} groupId 
     * @param {UpdateGroupViewModel} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public apiCourseGroupsByCourseIdUpdateByGroupIdPost(courseId: number, groupId: number, model?: UpdateGroupViewModel, options?: any) {
        return CourseGroupsApiFp(this.configuration).apiCourseGroupsByCourseIdUpdateByGroupIdPost(courseId, groupId, model, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public apiCourseGroupsGetByGroupIdGet(groupId: number, options?: any) {
        return CourseGroupsApiFp(this.configuration).apiCourseGroupsGetByGroupIdGet(groupId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CourseGroupsApi
     */
    public apiCourseGroupsGetTasksByGroupIdGet(groupId: number, options?: any) {
        return CourseGroupsApiFp(this.configuration).apiCourseGroupsGetTasksByGroupIdGet(groupId, options)(this.fetch, this.basePath);
    }

}

/**
 * CoursesApi - fetch parameter creator
 * @export
 */
export const CoursesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} courseId 
         * @param {string} lecturerEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesAcceptLecturerByCourseIdByLecturerEmailGet(courseId: number, lecturerEmail: string, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCoursesAcceptLecturerByCourseIdByLecturerEmailGet.');
            }
            // verify required parameter 'lecturerEmail' is not null or undefined
            if (lecturerEmail === null || lecturerEmail === undefined) {
                throw new RequiredError('lecturerEmail','Required parameter lecturerEmail was null or undefined when calling apiCoursesAcceptLecturerByCourseIdByLecturerEmailGet.');
            }
            const localVarPath = `/api/Courses/acceptLecturer/{courseId}/{lecturerEmail}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"lecturerEmail"}}`, encodeURIComponent(String(lecturerEmail)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesAcceptStudentByCourseIdByStudentIdPost(courseId: number, studentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCoursesAcceptStudentByCourseIdByStudentIdPost.');
            }
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling apiCoursesAcceptStudentByCourseIdByStudentIdPost.');
            }
            const localVarPath = `/api/Courses/acceptStudent/{courseId}/{studentId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"studentId"}}`, encodeURIComponent(String(studentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesByCourseIdDelete(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCoursesByCourseIdDelete.');
            }
            const localVarPath = `/api/Courses/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesByCourseIdGet(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCoursesByCourseIdGet.');
            }
            const localVarPath = `/api/Courses/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateCourseViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesCreatePost(model?: CreateCourseViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Courses/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateCourseViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(model || {}) : (model || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {string} mentorId 
         * @param {EditMentorWorkspaceDTO} [editMentorWorkspaceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesEditMentorWorkspaceByCourseIdByMentorIdPost(courseId: number, mentorId: string, editMentorWorkspaceDto?: EditMentorWorkspaceDTO, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCoursesEditMentorWorkspaceByCourseIdByMentorIdPost.');
            }
            // verify required parameter 'mentorId' is not null or undefined
            if (mentorId === null || mentorId === undefined) {
                throw new RequiredError('mentorId','Required parameter mentorId was null or undefined when calling apiCoursesEditMentorWorkspaceByCourseIdByMentorIdPost.');
            }
            const localVarPath = `/api/Courses/editMentorWorkspace/{courseId}/{mentorId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"mentorId"}}`, encodeURIComponent(String(mentorId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EditMentorWorkspaceDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(editMentorWorkspaceDto || {}) : (editMentorWorkspaceDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/Courses`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesGetAllDataByCourseIdGet(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCoursesGetAllDataByCourseIdGet.');
            }
            const localVarPath = `/api/Courses/getAllData/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesGetLecturersAvailableForCourseByCourseIdGet(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCoursesGetLecturersAvailableForCourseByCourseIdGet.');
            }
            const localVarPath = `/api/Courses/getLecturersAvailableForCourse/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {string} mentorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesGetMentorWorkspaceByCourseIdByMentorIdGet(courseId: number, mentorId: string, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCoursesGetMentorWorkspaceByCourseIdByMentorIdGet.');
            }
            // verify required parameter 'mentorId' is not null or undefined
            if (mentorId === null || mentorId === undefined) {
                throw new RequiredError('mentorId','Required parameter mentorId was null or undefined when calling apiCoursesGetMentorWorkspaceByCourseIdByMentorIdGet.');
            }
            const localVarPath = `/api/Courses/getMentorWorkspace/{courseId}/{mentorId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"mentorId"}}`, encodeURIComponent(String(mentorId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesRejectStudentByCourseIdByStudentIdPost(courseId: number, studentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCoursesRejectStudentByCourseIdByStudentIdPost.');
            }
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling apiCoursesRejectStudentByCourseIdByStudentIdPost.');
            }
            const localVarPath = `/api/Courses/rejectStudent/{courseId}/{studentId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"studentId"}}`, encodeURIComponent(String(studentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesSignInCourseByCourseIdPost(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCoursesSignInCourseByCourseIdPost.');
            }
            const localVarPath = `/api/Courses/signInCourse/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesTagsByCourseIdGet(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCoursesTagsByCourseIdGet.');
            }
            const localVarPath = `/api/Courses/tags/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {UpdateCourseViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesUpdateByCourseIdPost(courseId: number, model?: UpdateCourseViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiCoursesUpdateByCourseIdPost.');
            }
            const localVarPath = `/api/Courses/update/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateCourseViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(model || {}) : (model || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesUserCoursesGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/Courses/userCourses`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoursesApi - functional programming interface
 * @export
 */
export const CoursesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} courseId 
         * @param {string} lecturerEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesAcceptLecturerByCourseIdByLecturerEmailGet(courseId: number, lecturerEmail: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).apiCoursesAcceptLecturerByCourseIdByLecturerEmailGet(courseId, lecturerEmail, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesAcceptStudentByCourseIdByStudentIdPost(courseId: number, studentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).apiCoursesAcceptStudentByCourseIdByStudentIdPost(courseId, studentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesByCourseIdDelete(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).apiCoursesByCourseIdDelete(courseId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesByCourseIdGet(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CourseViewModel> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).apiCoursesByCourseIdGet(courseId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {CreateCourseViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesCreatePost(model?: CreateCourseViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).apiCoursesCreatePost(model, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {string} mentorId 
         * @param {EditMentorWorkspaceDTO} [editMentorWorkspaceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesEditMentorWorkspaceByCourseIdByMentorIdPost(courseId: number, mentorId: string, editMentorWorkspaceDto?: EditMentorWorkspaceDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).apiCoursesEditMentorWorkspaceByCourseIdByMentorIdPost(courseId, mentorId, editMentorWorkspaceDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CoursePreviewView>> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).apiCoursesGet(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesGetAllDataByCourseIdGet(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CourseViewModel> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).apiCoursesGetAllDataByCourseIdGet(courseId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesGetLecturersAvailableForCourseByCourseIdGet(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AccountDataDto>> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).apiCoursesGetLecturersAvailableForCourseByCourseIdGet(courseId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {string} mentorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesGetMentorWorkspaceByCourseIdByMentorIdGet(courseId: number, mentorId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WorkspaceViewModel> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).apiCoursesGetMentorWorkspaceByCourseIdByMentorIdGet(courseId, mentorId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesRejectStudentByCourseIdByStudentIdPost(courseId: number, studentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).apiCoursesRejectStudentByCourseIdByStudentIdPost(courseId, studentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesSignInCourseByCourseIdPost(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).apiCoursesSignInCourseByCourseIdPost(courseId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesTagsByCourseIdGet(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).apiCoursesTagsByCourseIdGet(courseId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {UpdateCourseViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesUpdateByCourseIdPost(courseId: number, model?: UpdateCourseViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).apiCoursesUpdateByCourseIdPost(courseId, model, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesUserCoursesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CoursePreviewView>> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).apiCoursesUserCoursesGet(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CoursesApi - factory interface
 * @export
 */
export const CoursesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} courseId 
         * @param {string} lecturerEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesAcceptLecturerByCourseIdByLecturerEmailGet(courseId: number, lecturerEmail: string, options?: any) {
            return CoursesApiFp(configuration).apiCoursesAcceptLecturerByCourseIdByLecturerEmailGet(courseId, lecturerEmail, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesAcceptStudentByCourseIdByStudentIdPost(courseId: number, studentId: string, options?: any) {
            return CoursesApiFp(configuration).apiCoursesAcceptStudentByCourseIdByStudentIdPost(courseId, studentId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesByCourseIdDelete(courseId: number, options?: any) {
            return CoursesApiFp(configuration).apiCoursesByCourseIdDelete(courseId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesByCourseIdGet(courseId: number, options?: any) {
            return CoursesApiFp(configuration).apiCoursesByCourseIdGet(courseId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {CreateCourseViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesCreatePost(model?: CreateCourseViewModel, options?: any) {
            return CoursesApiFp(configuration).apiCoursesCreatePost(model, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {string} mentorId 
         * @param {EditMentorWorkspaceDTO} [editMentorWorkspaceDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesEditMentorWorkspaceByCourseIdByMentorIdPost(courseId: number, mentorId: string, editMentorWorkspaceDto?: EditMentorWorkspaceDTO, options?: any) {
            return CoursesApiFp(configuration).apiCoursesEditMentorWorkspaceByCourseIdByMentorIdPost(courseId, mentorId, editMentorWorkspaceDto, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesGet(options?: any) {
            return CoursesApiFp(configuration).apiCoursesGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesGetAllDataByCourseIdGet(courseId: number, options?: any) {
            return CoursesApiFp(configuration).apiCoursesGetAllDataByCourseIdGet(courseId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesGetLecturersAvailableForCourseByCourseIdGet(courseId: number, options?: any) {
            return CoursesApiFp(configuration).apiCoursesGetLecturersAvailableForCourseByCourseIdGet(courseId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {string} mentorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesGetMentorWorkspaceByCourseIdByMentorIdGet(courseId: number, mentorId: string, options?: any) {
            return CoursesApiFp(configuration).apiCoursesGetMentorWorkspaceByCourseIdByMentorIdGet(courseId, mentorId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesRejectStudentByCourseIdByStudentIdPost(courseId: number, studentId: string, options?: any) {
            return CoursesApiFp(configuration).apiCoursesRejectStudentByCourseIdByStudentIdPost(courseId, studentId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesSignInCourseByCourseIdPost(courseId: number, options?: any) {
            return CoursesApiFp(configuration).apiCoursesSignInCourseByCourseIdPost(courseId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesTagsByCourseIdGet(courseId: number, options?: any) {
            return CoursesApiFp(configuration).apiCoursesTagsByCourseIdGet(courseId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {UpdateCourseViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesUpdateByCourseIdPost(courseId: number, model?: UpdateCourseViewModel, options?: any) {
            return CoursesApiFp(configuration).apiCoursesUpdateByCourseIdPost(courseId, model, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoursesUserCoursesGet(options?: any) {
            return CoursesApiFp(configuration).apiCoursesUserCoursesGet(options)(fetch, basePath);
        },
    };
};

/**
 * CoursesApi - object-oriented interface
 * @export
 * @class CoursesApi
 * @extends {BaseAPI}
 */
export class CoursesApi extends BaseAPI {
    /**
     * 
     * @param {number} courseId 
     * @param {string} lecturerEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public apiCoursesAcceptLecturerByCourseIdByLecturerEmailGet(courseId: number, lecturerEmail: string, options?: any) {
        return CoursesApiFp(this.configuration).apiCoursesAcceptLecturerByCourseIdByLecturerEmailGet(courseId, lecturerEmail, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {string} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public apiCoursesAcceptStudentByCourseIdByStudentIdPost(courseId: number, studentId: string, options?: any) {
        return CoursesApiFp(this.configuration).apiCoursesAcceptStudentByCourseIdByStudentIdPost(courseId, studentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public apiCoursesByCourseIdDelete(courseId: number, options?: any) {
        return CoursesApiFp(this.configuration).apiCoursesByCourseIdDelete(courseId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public apiCoursesByCourseIdGet(courseId: number, options?: any) {
        return CoursesApiFp(this.configuration).apiCoursesByCourseIdGet(courseId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {CreateCourseViewModel} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public apiCoursesCreatePost(model?: CreateCourseViewModel, options?: any) {
        return CoursesApiFp(this.configuration).apiCoursesCreatePost(model, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {string} mentorId 
     * @param {EditMentorWorkspaceDTO} [editMentorWorkspaceDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public apiCoursesEditMentorWorkspaceByCourseIdByMentorIdPost(courseId: number, mentorId: string, editMentorWorkspaceDto?: EditMentorWorkspaceDTO, options?: any) {
        return CoursesApiFp(this.configuration).apiCoursesEditMentorWorkspaceByCourseIdByMentorIdPost(courseId, mentorId, editMentorWorkspaceDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public apiCoursesGet(options?: any) {
        return CoursesApiFp(this.configuration).apiCoursesGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public apiCoursesGetAllDataByCourseIdGet(courseId: number, options?: any) {
        return CoursesApiFp(this.configuration).apiCoursesGetAllDataByCourseIdGet(courseId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public apiCoursesGetLecturersAvailableForCourseByCourseIdGet(courseId: number, options?: any) {
        return CoursesApiFp(this.configuration).apiCoursesGetLecturersAvailableForCourseByCourseIdGet(courseId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {string} mentorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public apiCoursesGetMentorWorkspaceByCourseIdByMentorIdGet(courseId: number, mentorId: string, options?: any) {
        return CoursesApiFp(this.configuration).apiCoursesGetMentorWorkspaceByCourseIdByMentorIdGet(courseId, mentorId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {string} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public apiCoursesRejectStudentByCourseIdByStudentIdPost(courseId: number, studentId: string, options?: any) {
        return CoursesApiFp(this.configuration).apiCoursesRejectStudentByCourseIdByStudentIdPost(courseId, studentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public apiCoursesSignInCourseByCourseIdPost(courseId: number, options?: any) {
        return CoursesApiFp(this.configuration).apiCoursesSignInCourseByCourseIdPost(courseId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public apiCoursesTagsByCourseIdGet(courseId: number, options?: any) {
        return CoursesApiFp(this.configuration).apiCoursesTagsByCourseIdGet(courseId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {UpdateCourseViewModel} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public apiCoursesUpdateByCourseIdPost(courseId: number, model?: UpdateCourseViewModel, options?: any) {
        return CoursesApiFp(this.configuration).apiCoursesUpdateByCourseIdPost(courseId, model, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public apiCoursesUserCoursesGet(options?: any) {
        return CoursesApiFp(this.configuration).apiCoursesUserCoursesGet(options)(this.fetch, this.basePath);
    }

}

/**
 * ExpertsApi - fetch parameter creator
 * @export
 */
export const ExpertsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsGetAllGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/Experts/getAll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [expertEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsGetTokenGet(expertEmail?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/Experts/getToken`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (expertEmail !== undefined) {
                localVarQueryParameter['expertEmail'] = expertEmail;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InviteExpertViewModel} [inviteExpertView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsInvitePost(inviteExpertView?: InviteExpertViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Experts/invite`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InviteExpertViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(inviteExpertView || {}) : (inviteExpertView || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsIsProfileEditedGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/Experts/isProfileEdited`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TokenCredentials} [credentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsLoginPost(credentials?: TokenCredentials, options: any = {}): FetchArgs {
            const localVarPath = `/api/Experts/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TokenCredentials" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(credentials || {}) : (credentials || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterExpertViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsRegisterPost(model?: RegisterExpertViewModel, options: any = {}): FetchArgs {
            const localVarPath = `/api/Experts/register`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RegisterExpertViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(model || {}) : (model || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsSetProfileIsEditedPost(options: any = {}): FetchArgs {
            const localVarPath = `/api/Experts/setProfileIsEdited`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateExpertTagsDTO} [updateExpertTagsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsUpdateTagsPost(updateExpertTagsDto?: UpdateExpertTagsDTO, options: any = {}): FetchArgs {
            const localVarPath = `/api/Experts/updateTags`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateExpertTagsDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateExpertTagsDto || {}) : (updateExpertTagsDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExpertsApi - functional programming interface
 * @export
 */
export const ExpertsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsGetAllGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ExpertDataDTO>> {
            const localVarFetchArgs = ExpertsApiFetchParamCreator(configuration).apiExpertsGetAllGet(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [expertEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsGetTokenGet(expertEmail?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResultTokenCredentials> {
            const localVarFetchArgs = ExpertsApiFetchParamCreator(configuration).apiExpertsGetTokenGet(expertEmail, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {InviteExpertViewModel} [inviteExpertView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsInvitePost(inviteExpertView?: InviteExpertViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = ExpertsApiFetchParamCreator(configuration).apiExpertsInvitePost(inviteExpertView, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsIsProfileEditedGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResultBoolean> {
            const localVarFetchArgs = ExpertsApiFetchParamCreator(configuration).apiExpertsIsProfileEditedGet(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {TokenCredentials} [credentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsLoginPost(credentials?: TokenCredentials, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = ExpertsApiFetchParamCreator(configuration).apiExpertsLoginPost(credentials, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {RegisterExpertViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsRegisterPost(model?: RegisterExpertViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = ExpertsApiFetchParamCreator(configuration).apiExpertsRegisterPost(model, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsSetProfileIsEditedPost(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = ExpertsApiFetchParamCreator(configuration).apiExpertsSetProfileIsEditedPost(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {UpdateExpertTagsDTO} [updateExpertTagsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsUpdateTagsPost(updateExpertTagsDto?: UpdateExpertTagsDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Result> {
            const localVarFetchArgs = ExpertsApiFetchParamCreator(configuration).apiExpertsUpdateTagsPost(updateExpertTagsDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ExpertsApi - factory interface
 * @export
 */
export const ExpertsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsGetAllGet(options?: any) {
            return ExpertsApiFp(configuration).apiExpertsGetAllGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [expertEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsGetTokenGet(expertEmail?: string, options?: any) {
            return ExpertsApiFp(configuration).apiExpertsGetTokenGet(expertEmail, options)(fetch, basePath);
        },
        /**
         * 
         * @param {InviteExpertViewModel} [inviteExpertView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsInvitePost(inviteExpertView?: InviteExpertViewModel, options?: any) {
            return ExpertsApiFp(configuration).apiExpertsInvitePost(inviteExpertView, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsIsProfileEditedGet(options?: any) {
            return ExpertsApiFp(configuration).apiExpertsIsProfileEditedGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {TokenCredentials} [credentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsLoginPost(credentials?: TokenCredentials, options?: any) {
            return ExpertsApiFp(configuration).apiExpertsLoginPost(credentials, options)(fetch, basePath);
        },
        /**
         * 
         * @param {RegisterExpertViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsRegisterPost(model?: RegisterExpertViewModel, options?: any) {
            return ExpertsApiFp(configuration).apiExpertsRegisterPost(model, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsSetProfileIsEditedPost(options?: any) {
            return ExpertsApiFp(configuration).apiExpertsSetProfileIsEditedPost(options)(fetch, basePath);
        },
        /**
         * 
         * @param {UpdateExpertTagsDTO} [updateExpertTagsDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiExpertsUpdateTagsPost(updateExpertTagsDto?: UpdateExpertTagsDTO, options?: any) {
            return ExpertsApiFp(configuration).apiExpertsUpdateTagsPost(updateExpertTagsDto, options)(fetch, basePath);
        },
    };
};

/**
 * ExpertsApi - object-oriented interface
 * @export
 * @class ExpertsApi
 * @extends {BaseAPI}
 */
export class ExpertsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public apiExpertsGetAllGet(options?: any) {
        return ExpertsApiFp(this.configuration).apiExpertsGetAllGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [expertEmail] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public apiExpertsGetTokenGet(expertEmail?: string, options?: any) {
        return ExpertsApiFp(this.configuration).apiExpertsGetTokenGet(expertEmail, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {InviteExpertViewModel} [inviteExpertView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public apiExpertsInvitePost(inviteExpertView?: InviteExpertViewModel, options?: any) {
        return ExpertsApiFp(this.configuration).apiExpertsInvitePost(inviteExpertView, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public apiExpertsIsProfileEditedGet(options?: any) {
        return ExpertsApiFp(this.configuration).apiExpertsIsProfileEditedGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {TokenCredentials} [credentials] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public apiExpertsLoginPost(credentials?: TokenCredentials, options?: any) {
        return ExpertsApiFp(this.configuration).apiExpertsLoginPost(credentials, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {RegisterExpertViewModel} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public apiExpertsRegisterPost(model?: RegisterExpertViewModel, options?: any) {
        return ExpertsApiFp(this.configuration).apiExpertsRegisterPost(model, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public apiExpertsSetProfileIsEditedPost(options?: any) {
        return ExpertsApiFp(this.configuration).apiExpertsSetProfileIsEditedPost(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {UpdateExpertTagsDTO} [updateExpertTagsDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExpertsApi
     */
    public apiExpertsUpdateTagsPost(updateExpertTagsDto?: UpdateExpertTagsDTO, options?: any) {
        return ExpertsApiFp(this.configuration).apiExpertsUpdateTagsPost(updateExpertTagsDto, options)(this.fetch, this.basePath);
    }

}

/**
 * HomeworksApi - fetch parameter creator
 * @export
 */
export const HomeworksApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} courseId 
         * @param {CreateHomeworkViewModel} [homeworkViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHomeworksByCourseIdAddPost(courseId: number, homeworkViewModel?: CreateHomeworkViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiHomeworksByCourseIdAddPost.');
            }
            const localVarPath = `/api/Homeworks/{courseId}/add`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateHomeworkViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(homeworkViewModel || {}) : (homeworkViewModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} homeworkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHomeworksDeleteByHomeworkIdDelete(homeworkId: number, options: any = {}): FetchArgs {
            // verify required parameter 'homeworkId' is not null or undefined
            if (homeworkId === null || homeworkId === undefined) {
                throw new RequiredError('homeworkId','Required parameter homeworkId was null or undefined when calling apiHomeworksDeleteByHomeworkIdDelete.');
            }
            const localVarPath = `/api/Homeworks/delete/{homeworkId}`
                .replace(`{${"homeworkId"}}`, encodeURIComponent(String(homeworkId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} homeworkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHomeworksGetByHomeworkIdGet(homeworkId: number, options: any = {}): FetchArgs {
            // verify required parameter 'homeworkId' is not null or undefined
            if (homeworkId === null || homeworkId === undefined) {
                throw new RequiredError('homeworkId','Required parameter homeworkId was null or undefined when calling apiHomeworksGetByHomeworkIdGet.');
            }
            const localVarPath = `/api/Homeworks/get/{homeworkId}`
                .replace(`{${"homeworkId"}}`, encodeURIComponent(String(homeworkId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} homeworkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHomeworksGetForEditingByHomeworkIdGet(homeworkId: number, options: any = {}): FetchArgs {
            // verify required parameter 'homeworkId' is not null or undefined
            if (homeworkId === null || homeworkId === undefined) {
                throw new RequiredError('homeworkId','Required parameter homeworkId was null or undefined when calling apiHomeworksGetForEditingByHomeworkIdGet.');
            }
            const localVarPath = `/api/Homeworks/getForEditing/{homeworkId}`
                .replace(`{${"homeworkId"}}`, encodeURIComponent(String(homeworkId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} homeworkId 
         * @param {CreateHomeworkViewModel} [homeworkViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHomeworksUpdateByHomeworkIdPut(homeworkId: number, homeworkViewModel?: CreateHomeworkViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'homeworkId' is not null or undefined
            if (homeworkId === null || homeworkId === undefined) {
                throw new RequiredError('homeworkId','Required parameter homeworkId was null or undefined when calling apiHomeworksUpdateByHomeworkIdPut.');
            }
            const localVarPath = `/api/Homeworks/update/{homeworkId}`
                .replace(`{${"homeworkId"}}`, encodeURIComponent(String(homeworkId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateHomeworkViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(homeworkViewModel || {}) : (homeworkViewModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HomeworksApi - functional programming interface
 * @export
 */
export const HomeworksApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} courseId 
         * @param {CreateHomeworkViewModel} [homeworkViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHomeworksByCourseIdAddPost(courseId: number, homeworkViewModel?: CreateHomeworkViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = HomeworksApiFetchParamCreator(configuration).apiHomeworksByCourseIdAddPost(courseId, homeworkViewModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} homeworkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHomeworksDeleteByHomeworkIdDelete(homeworkId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = HomeworksApiFetchParamCreator(configuration).apiHomeworksDeleteByHomeworkIdDelete(homeworkId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} homeworkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHomeworksGetByHomeworkIdGet(homeworkId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HomeworkViewModel> {
            const localVarFetchArgs = HomeworksApiFetchParamCreator(configuration).apiHomeworksGetByHomeworkIdGet(homeworkId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} homeworkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHomeworksGetForEditingByHomeworkIdGet(homeworkId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HomeworkViewModel> {
            const localVarFetchArgs = HomeworksApiFetchParamCreator(configuration).apiHomeworksGetForEditingByHomeworkIdGet(homeworkId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} homeworkId 
         * @param {CreateHomeworkViewModel} [homeworkViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHomeworksUpdateByHomeworkIdPut(homeworkId: number, homeworkViewModel?: CreateHomeworkViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = HomeworksApiFetchParamCreator(configuration).apiHomeworksUpdateByHomeworkIdPut(homeworkId, homeworkViewModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * HomeworksApi - factory interface
 * @export
 */
export const HomeworksApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} courseId 
         * @param {CreateHomeworkViewModel} [homeworkViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHomeworksByCourseIdAddPost(courseId: number, homeworkViewModel?: CreateHomeworkViewModel, options?: any) {
            return HomeworksApiFp(configuration).apiHomeworksByCourseIdAddPost(courseId, homeworkViewModel, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} homeworkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHomeworksDeleteByHomeworkIdDelete(homeworkId: number, options?: any) {
            return HomeworksApiFp(configuration).apiHomeworksDeleteByHomeworkIdDelete(homeworkId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} homeworkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHomeworksGetByHomeworkIdGet(homeworkId: number, options?: any) {
            return HomeworksApiFp(configuration).apiHomeworksGetByHomeworkIdGet(homeworkId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} homeworkId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHomeworksGetForEditingByHomeworkIdGet(homeworkId: number, options?: any) {
            return HomeworksApiFp(configuration).apiHomeworksGetForEditingByHomeworkIdGet(homeworkId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} homeworkId 
         * @param {CreateHomeworkViewModel} [homeworkViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiHomeworksUpdateByHomeworkIdPut(homeworkId: number, homeworkViewModel?: CreateHomeworkViewModel, options?: any) {
            return HomeworksApiFp(configuration).apiHomeworksUpdateByHomeworkIdPut(homeworkId, homeworkViewModel, options)(fetch, basePath);
        },
    };
};

/**
 * HomeworksApi - object-oriented interface
 * @export
 * @class HomeworksApi
 * @extends {BaseAPI}
 */
export class HomeworksApi extends BaseAPI {
    /**
     * 
     * @param {number} courseId 
     * @param {CreateHomeworkViewModel} [homeworkViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeworksApi
     */
    public apiHomeworksByCourseIdAddPost(courseId: number, homeworkViewModel?: CreateHomeworkViewModel, options?: any) {
        return HomeworksApiFp(this.configuration).apiHomeworksByCourseIdAddPost(courseId, homeworkViewModel, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} homeworkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeworksApi
     */
    public apiHomeworksDeleteByHomeworkIdDelete(homeworkId: number, options?: any) {
        return HomeworksApiFp(this.configuration).apiHomeworksDeleteByHomeworkIdDelete(homeworkId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} homeworkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeworksApi
     */
    public apiHomeworksGetByHomeworkIdGet(homeworkId: number, options?: any) {
        return HomeworksApiFp(this.configuration).apiHomeworksGetByHomeworkIdGet(homeworkId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} homeworkId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeworksApi
     */
    public apiHomeworksGetForEditingByHomeworkIdGet(homeworkId: number, options?: any) {
        return HomeworksApiFp(this.configuration).apiHomeworksGetForEditingByHomeworkIdGet(homeworkId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} homeworkId 
     * @param {CreateHomeworkViewModel} [homeworkViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HomeworksApi
     */
    public apiHomeworksUpdateByHomeworkIdPut(homeworkId: number, homeworkViewModel?: CreateHomeworkViewModel, options?: any) {
        return HomeworksApiFp(this.configuration).apiHomeworksUpdateByHomeworkIdPut(homeworkId, homeworkViewModel, options)(this.fetch, this.basePath);
    }

}

/**
 * NotificationsApi - fetch parameter creator
 * @export
 */
export const NotificationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsGetGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/Notifications/get`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsGetNewNotificationsCountGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/Notifications/getNewNotificationsCount`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [notificationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsMarkAsSeenPut(notificationIds?: Array<number>, options: any = {}): FetchArgs {
            const localVarPath = `/api/Notifications/markAsSeen`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;number&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(notificationIds || {}) : (notificationIds || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsSettingsGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/Notifications/settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NotificationsSettingDto} [newSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsSettingsPut(newSetting?: NotificationsSettingDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/Notifications/settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NotificationsSettingDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(newSetting || {}) : (newSetting || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsGetGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CategorizedNotifications>> {
            const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).apiNotificationsGetGet(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsGetNewNotificationsCountGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).apiNotificationsGetNewNotificationsCountGet(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {Array<number>} [notificationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsMarkAsSeenPut(notificationIds?: Array<number>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).apiNotificationsMarkAsSeenPut(notificationIds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsSettingsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<NotificationsSettingDto>> {
            const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).apiNotificationsSettingsGet(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {NotificationsSettingDto} [newSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsSettingsPut(newSetting?: NotificationsSettingDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).apiNotificationsSettingsPut(newSetting, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsGetGet(options?: any) {
            return NotificationsApiFp(configuration).apiNotificationsGetGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsGetNewNotificationsCountGet(options?: any) {
            return NotificationsApiFp(configuration).apiNotificationsGetNewNotificationsCountGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {Array<number>} [notificationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsMarkAsSeenPut(notificationIds?: Array<number>, options?: any) {
            return NotificationsApiFp(configuration).apiNotificationsMarkAsSeenPut(notificationIds, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsSettingsGet(options?: any) {
            return NotificationsApiFp(configuration).apiNotificationsSettingsGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {NotificationsSettingDto} [newSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNotificationsSettingsPut(newSetting?: NotificationsSettingDto, options?: any) {
            return NotificationsApiFp(configuration).apiNotificationsSettingsPut(newSetting, options)(fetch, basePath);
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiNotificationsGetGet(options?: any) {
        return NotificationsApiFp(this.configuration).apiNotificationsGetGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiNotificationsGetNewNotificationsCountGet(options?: any) {
        return NotificationsApiFp(this.configuration).apiNotificationsGetNewNotificationsCountGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {Array<number>} [notificationIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiNotificationsMarkAsSeenPut(notificationIds?: Array<number>, options?: any) {
        return NotificationsApiFp(this.configuration).apiNotificationsMarkAsSeenPut(notificationIds, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiNotificationsSettingsGet(options?: any) {
        return NotificationsApiFp(this.configuration).apiNotificationsSettingsGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {NotificationsSettingDto} [newSetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public apiNotificationsSettingsPut(newSetting?: NotificationsSettingDto, options?: any) {
        return NotificationsApiFp(this.configuration).apiNotificationsSettingsPut(newSetting, options)(this.fetch, this.basePath);
    }

}

/**
 * SolutionsApi - fetch parameter creator
 * @export
 */
export const SolutionsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} solutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsActualityBySolutionIdGet(solutionId: number, options: any = {}): FetchArgs {
            // verify required parameter 'solutionId' is not null or undefined
            if (solutionId === null || solutionId === undefined) {
                throw new RequiredError('solutionId','Required parameter solutionId was null or undefined when calling apiSolutionsActualityBySolutionIdGet.');
            }
            const localVarPath = `/api/Solutions/actuality/{solutionId}`
                .replace(`{${"solutionId"}}`, encodeURIComponent(String(solutionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} solutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsBySolutionIdGet(solutionId: number, options: any = {}): FetchArgs {
            // verify required parameter 'solutionId' is not null or undefined
            if (solutionId === null || solutionId === undefined) {
                throw new RequiredError('solutionId','Required parameter solutionId was null or undefined when calling apiSolutionsBySolutionIdGet.');
            }
            const localVarPath = `/api/Solutions/{solutionId}`
                .replace(`{${"solutionId"}}`, encodeURIComponent(String(solutionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {SolutionViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsByTaskIdPost(taskId: number, model?: SolutionViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling apiSolutionsByTaskIdPost.');
            }
            const localVarPath = `/api/Solutions/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SolutionViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(model || {}) : (model || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} solutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsDeleteBySolutionIdDelete(solutionId: number, options: any = {}): FetchArgs {
            // verify required parameter 'solutionId' is not null or undefined
            if (solutionId === null || solutionId === undefined) {
                throw new RequiredError('solutionId','Required parameter solutionId was null or undefined when calling apiSolutionsDeleteBySolutionIdDelete.');
            }
            const localVarPath = `/api/Solutions/delete/{solutionId}`
                .replace(`{${"solutionId"}}`, encodeURIComponent(String(solutionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsGiveUpByTaskIdPost(taskId: number, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling apiSolutionsGiveUpByTaskIdPost.');
            }
            const localVarPath = `/api/Solutions/giveUp/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} solutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsMarkSolutionFinalBySolutionIdPost(solutionId: number, options: any = {}): FetchArgs {
            // verify required parameter 'solutionId' is not null or undefined
            if (solutionId === null || solutionId === undefined) {
                throw new RequiredError('solutionId','Required parameter solutionId was null or undefined when calling apiSolutionsMarkSolutionFinalBySolutionIdPost.');
            }
            const localVarPath = `/api/Solutions/markSolutionFinal/{solutionId}`
                .replace(`{${"solutionId"}}`, encodeURIComponent(String(solutionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {SolutionViewModel} [solution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsRateEmptySolutionByTaskIdPost(taskId: number, solution?: SolutionViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling apiSolutionsRateEmptySolutionByTaskIdPost.');
            }
            const localVarPath = `/api/Solutions/rateEmptySolution/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SolutionViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(solution || {}) : (solution || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} solutionId 
         * @param {RateSolutionModel} [rateSolutionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsRateSolutionBySolutionIdPost(solutionId: number, rateSolutionModel?: RateSolutionModel, options: any = {}): FetchArgs {
            // verify required parameter 'solutionId' is not null or undefined
            if (solutionId === null || solutionId === undefined) {
                throw new RequiredError('solutionId','Required parameter solutionId was null or undefined when calling apiSolutionsRateSolutionBySolutionIdPost.');
            }
            const localVarPath = `/api/Solutions/rateSolution/{solutionId}`
                .replace(`{${"solutionId"}}`, encodeURIComponent(String(solutionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RateSolutionModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(rateSolutionModel || {}) : (rateSolutionModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [taskId] 
         * @param {number} [solutionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsSolutionAchievementGet(taskId?: number, solutionId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/Solutions/solutionAchievement`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['taskId'] = taskId;
            }

            if (solutionId !== undefined) {
                localVarQueryParameter['solutionId'] = solutionId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsTaskSolutionByTaskIdByStudentIdGet(taskId: number, studentId: string, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling apiSolutionsTaskSolutionByTaskIdByStudentIdGet.');
            }
            // verify required parameter 'studentId' is not null or undefined
            if (studentId === null || studentId === undefined) {
                throw new RequiredError('studentId','Required parameter studentId was null or undefined when calling apiSolutionsTaskSolutionByTaskIdByStudentIdGet.');
            }
            const localVarPath = `/api/Solutions/taskSolution/{taskId}/{studentId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)))
                .replace(`{${"studentId"}}`, encodeURIComponent(String(studentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsTasksByTaskIdGet(taskId: number, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling apiSolutionsTasksByTaskIdGet.');
            }
            const localVarPath = `/api/Solutions/tasks/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [taskId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsUnratedSolutionsGet(taskId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/Solutions/unratedSolutions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (taskId !== undefined) {
                localVarQueryParameter['taskId'] = taskId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SolutionsApi - functional programming interface
 * @export
 */
export const SolutionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} solutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsActualityBySolutionIdGet(solutionId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SolutionActualityDto> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).apiSolutionsActualityBySolutionIdGet(solutionId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} solutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsBySolutionIdGet(solutionId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Solution> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).apiSolutionsBySolutionIdGet(solutionId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {SolutionViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsByTaskIdPost(taskId: number, model?: SolutionViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).apiSolutionsByTaskIdPost(taskId, model, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} solutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsDeleteBySolutionIdDelete(solutionId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).apiSolutionsDeleteBySolutionIdDelete(solutionId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsGiveUpByTaskIdPost(taskId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).apiSolutionsGiveUpByTaskIdPost(taskId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} solutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsMarkSolutionFinalBySolutionIdPost(solutionId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).apiSolutionsMarkSolutionFinalBySolutionIdPost(solutionId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {SolutionViewModel} [solution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsRateEmptySolutionByTaskIdPost(taskId: number, solution?: SolutionViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).apiSolutionsRateEmptySolutionByTaskIdPost(taskId, solution, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} solutionId 
         * @param {RateSolutionModel} [rateSolutionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsRateSolutionBySolutionIdPost(solutionId: number, rateSolutionModel?: RateSolutionModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).apiSolutionsRateSolutionBySolutionIdPost(solutionId, rateSolutionModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [taskId] 
         * @param {number} [solutionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsSolutionAchievementGet(taskId?: number, solutionId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).apiSolutionsSolutionAchievementGet(taskId, solutionId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsTaskSolutionByTaskIdByStudentIdGet(taskId: number, studentId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserTaskSolutionsPageData> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).apiSolutionsTaskSolutionByTaskIdByStudentIdGet(taskId, studentId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsTasksByTaskIdGet(taskId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TaskSolutionStatisticsPageData> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).apiSolutionsTasksByTaskIdGet(taskId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [taskId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsUnratedSolutionsGet(taskId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UnratedSolutionPreviews> {
            const localVarFetchArgs = SolutionsApiFetchParamCreator(configuration).apiSolutionsUnratedSolutionsGet(taskId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SolutionsApi - factory interface
 * @export
 */
export const SolutionsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} solutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsActualityBySolutionIdGet(solutionId: number, options?: any) {
            return SolutionsApiFp(configuration).apiSolutionsActualityBySolutionIdGet(solutionId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} solutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsBySolutionIdGet(solutionId: number, options?: any) {
            return SolutionsApiFp(configuration).apiSolutionsBySolutionIdGet(solutionId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} taskId 
         * @param {SolutionViewModel} [model] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsByTaskIdPost(taskId: number, model?: SolutionViewModel, options?: any) {
            return SolutionsApiFp(configuration).apiSolutionsByTaskIdPost(taskId, model, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} solutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsDeleteBySolutionIdDelete(solutionId: number, options?: any) {
            return SolutionsApiFp(configuration).apiSolutionsDeleteBySolutionIdDelete(solutionId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsGiveUpByTaskIdPost(taskId: number, options?: any) {
            return SolutionsApiFp(configuration).apiSolutionsGiveUpByTaskIdPost(taskId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} solutionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsMarkSolutionFinalBySolutionIdPost(solutionId: number, options?: any) {
            return SolutionsApiFp(configuration).apiSolutionsMarkSolutionFinalBySolutionIdPost(solutionId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} taskId 
         * @param {SolutionViewModel} [solution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsRateEmptySolutionByTaskIdPost(taskId: number, solution?: SolutionViewModel, options?: any) {
            return SolutionsApiFp(configuration).apiSolutionsRateEmptySolutionByTaskIdPost(taskId, solution, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} solutionId 
         * @param {RateSolutionModel} [rateSolutionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsRateSolutionBySolutionIdPost(solutionId: number, rateSolutionModel?: RateSolutionModel, options?: any) {
            return SolutionsApiFp(configuration).apiSolutionsRateSolutionBySolutionIdPost(solutionId, rateSolutionModel, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [taskId] 
         * @param {number} [solutionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsSolutionAchievementGet(taskId?: number, solutionId?: number, options?: any) {
            return SolutionsApiFp(configuration).apiSolutionsSolutionAchievementGet(taskId, solutionId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} taskId 
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsTaskSolutionByTaskIdByStudentIdGet(taskId: number, studentId: string, options?: any) {
            return SolutionsApiFp(configuration).apiSolutionsTaskSolutionByTaskIdByStudentIdGet(taskId, studentId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsTasksByTaskIdGet(taskId: number, options?: any) {
            return SolutionsApiFp(configuration).apiSolutionsTasksByTaskIdGet(taskId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [taskId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSolutionsUnratedSolutionsGet(taskId?: number, options?: any) {
            return SolutionsApiFp(configuration).apiSolutionsUnratedSolutionsGet(taskId, options)(fetch, basePath);
        },
    };
};

/**
 * SolutionsApi - object-oriented interface
 * @export
 * @class SolutionsApi
 * @extends {BaseAPI}
 */
export class SolutionsApi extends BaseAPI {
    /**
     * 
     * @param {number} solutionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public apiSolutionsActualityBySolutionIdGet(solutionId: number, options?: any) {
        return SolutionsApiFp(this.configuration).apiSolutionsActualityBySolutionIdGet(solutionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} solutionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public apiSolutionsBySolutionIdGet(solutionId: number, options?: any) {
        return SolutionsApiFp(this.configuration).apiSolutionsBySolutionIdGet(solutionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} taskId 
     * @param {SolutionViewModel} [model] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public apiSolutionsByTaskIdPost(taskId: number, model?: SolutionViewModel, options?: any) {
        return SolutionsApiFp(this.configuration).apiSolutionsByTaskIdPost(taskId, model, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} solutionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public apiSolutionsDeleteBySolutionIdDelete(solutionId: number, options?: any) {
        return SolutionsApiFp(this.configuration).apiSolutionsDeleteBySolutionIdDelete(solutionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public apiSolutionsGiveUpByTaskIdPost(taskId: number, options?: any) {
        return SolutionsApiFp(this.configuration).apiSolutionsGiveUpByTaskIdPost(taskId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} solutionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public apiSolutionsMarkSolutionFinalBySolutionIdPost(solutionId: number, options?: any) {
        return SolutionsApiFp(this.configuration).apiSolutionsMarkSolutionFinalBySolutionIdPost(solutionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} taskId 
     * @param {SolutionViewModel} [solution] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public apiSolutionsRateEmptySolutionByTaskIdPost(taskId: number, solution?: SolutionViewModel, options?: any) {
        return SolutionsApiFp(this.configuration).apiSolutionsRateEmptySolutionByTaskIdPost(taskId, solution, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} solutionId 
     * @param {RateSolutionModel} [rateSolutionModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public apiSolutionsRateSolutionBySolutionIdPost(solutionId: number, rateSolutionModel?: RateSolutionModel, options?: any) {
        return SolutionsApiFp(this.configuration).apiSolutionsRateSolutionBySolutionIdPost(solutionId, rateSolutionModel, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [taskId] 
     * @param {number} [solutionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public apiSolutionsSolutionAchievementGet(taskId?: number, solutionId?: number, options?: any) {
        return SolutionsApiFp(this.configuration).apiSolutionsSolutionAchievementGet(taskId, solutionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} taskId 
     * @param {string} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public apiSolutionsTaskSolutionByTaskIdByStudentIdGet(taskId: number, studentId: string, options?: any) {
        return SolutionsApiFp(this.configuration).apiSolutionsTaskSolutionByTaskIdByStudentIdGet(taskId, studentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public apiSolutionsTasksByTaskIdGet(taskId: number, options?: any) {
        return SolutionsApiFp(this.configuration).apiSolutionsTasksByTaskIdGet(taskId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [taskId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SolutionsApi
     */
    public apiSolutionsUnratedSolutionsGet(taskId?: number, options?: any) {
        return SolutionsApiFp(this.configuration).apiSolutionsUnratedSolutionsGet(taskId, options)(this.fetch, this.basePath);
    }

}

/**
 * StatisticsApi - fetch parameter creator
 * @export
 */
export const StatisticsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsByCourseIdChartsGet(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiStatisticsByCourseIdChartsGet.');
            }
            const localVarPath = `/api/Statistics/{courseId}/charts`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsByCourseIdGet(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiStatisticsByCourseIdGet.');
            }
            const localVarPath = `/api/Statistics/{courseId}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsByCourseIdLecturersGet(courseId: number, options: any = {}): FetchArgs {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling apiStatisticsByCourseIdLecturersGet.');
            }
            const localVarPath = `/api/Statistics/{courseId}/lecturers`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsByCourseIdChartsGet(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdvancedCourseStatisticsViewModel> {
            const localVarFetchArgs = StatisticsApiFetchParamCreator(configuration).apiStatisticsByCourseIdChartsGet(courseId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsByCourseIdGet(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<StatisticsCourseMatesModel>> {
            const localVarFetchArgs = StatisticsApiFetchParamCreator(configuration).apiStatisticsByCourseIdGet(courseId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsByCourseIdLecturersGet(courseId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<StatisticsLecturersModel>> {
            const localVarFetchArgs = StatisticsApiFetchParamCreator(configuration).apiStatisticsByCourseIdLecturersGet(courseId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsByCourseIdChartsGet(courseId: number, options?: any) {
            return StatisticsApiFp(configuration).apiStatisticsByCourseIdChartsGet(courseId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsByCourseIdGet(courseId: number, options?: any) {
            return StatisticsApiFp(configuration).apiStatisticsByCourseIdGet(courseId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} courseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStatisticsByCourseIdLecturersGet(courseId: number, options?: any) {
            return StatisticsApiFp(configuration).apiStatisticsByCourseIdLecturersGet(courseId, options)(fetch, basePath);
        },
    };
};

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
    /**
     * 
     * @param {number} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public apiStatisticsByCourseIdChartsGet(courseId: number, options?: any) {
        return StatisticsApiFp(this.configuration).apiStatisticsByCourseIdChartsGet(courseId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public apiStatisticsByCourseIdGet(courseId: number, options?: any) {
        return StatisticsApiFp(this.configuration).apiStatisticsByCourseIdGet(courseId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} courseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public apiStatisticsByCourseIdLecturersGet(courseId: number, options?: any) {
        return StatisticsApiFp(this.configuration).apiStatisticsByCourseIdLecturersGet(courseId, options)(this.fetch, this.basePath);
    }

}

/**
 * SystemApi - fetch parameter creator
 * @export
 */
export const SystemApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemStatusGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/System/status`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemStatusGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SystemInfo>> {
            const localVarFetchArgs = SystemApiFetchParamCreator(configuration).apiSystemStatusGet(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSystemStatusGet(options?: any) {
            return SystemApiFp(configuration).apiSystemStatusGet(options)(fetch, basePath);
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public apiSystemStatusGet(options?: any) {
        return SystemApiFp(this.configuration).apiSystemStatusGet(options)(this.fetch, this.basePath);
    }

}

/**
 * TasksApi - fetch parameter creator
 * @export
 */
export const TasksApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddAnswerForQuestionDto} [answer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksAddAnswerPost(answer?: AddAnswerForQuestionDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/Tasks/addAnswer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddAnswerForQuestionDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(answer || {}) : (answer || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} homeworkId 
         * @param {CreateTaskViewModel} [taskViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksAddByHomeworkIdPost(homeworkId: number, taskViewModel?: CreateTaskViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'homeworkId' is not null or undefined
            if (homeworkId === null || homeworkId === undefined) {
                throw new RequiredError('homeworkId','Required parameter homeworkId was null or undefined when calling apiTasksAddByHomeworkIdPost.');
            }
            const localVarPath = `/api/Tasks/add/{homeworkId}`
                .replace(`{${"homeworkId"}}`, encodeURIComponent(String(homeworkId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateTaskViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(taskViewModel || {}) : (taskViewModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddTaskQuestionDto} [question] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksAddQuestionPost(question?: AddTaskQuestionDto, options: any = {}): FetchArgs {
            const localVarPath = `/api/Tasks/addQuestion`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddTaskQuestionDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(question || {}) : (question || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksDeleteByTaskIdDelete(taskId: number, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling apiTasksDeleteByTaskIdDelete.');
            }
            const localVarPath = `/api/Tasks/delete/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksGetByTaskIdGet(taskId: number, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling apiTasksGetByTaskIdGet.');
            }
            const localVarPath = `/api/Tasks/get/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksGetForEditingByTaskIdGet(taskId: number, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling apiTasksGetForEditingByTaskIdGet.');
            }
            const localVarPath = `/api/Tasks/getForEditing/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksQuestionsByTaskIdGet(taskId: number, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling apiTasksQuestionsByTaskIdGet.');
            }
            const localVarPath = `/api/Tasks/questions/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {CreateTaskViewModel} [taskViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksUpdateByTaskIdPut(taskId: number, taskViewModel?: CreateTaskViewModel, options: any = {}): FetchArgs {
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError('taskId','Required parameter taskId was null or undefined when calling apiTasksUpdateByTaskIdPut.');
            }
            const localVarPath = `/api/Tasks/update/{taskId}`
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CreateTaskViewModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(taskViewModel || {}) : (taskViewModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddAnswerForQuestionDto} [answer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksAddAnswerPost(answer?: AddAnswerForQuestionDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).apiTasksAddAnswerPost(answer, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} homeworkId 
         * @param {CreateTaskViewModel} [taskViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksAddByHomeworkIdPost(homeworkId: number, taskViewModel?: CreateTaskViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).apiTasksAddByHomeworkIdPost(homeworkId, taskViewModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {AddTaskQuestionDto} [question] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksAddQuestionPost(question?: AddTaskQuestionDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).apiTasksAddQuestionPost(question, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksDeleteByTaskIdDelete(taskId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).apiTasksDeleteByTaskIdDelete(taskId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksGetByTaskIdGet(taskId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HomeworkTaskViewModel> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).apiTasksGetByTaskIdGet(taskId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksGetForEditingByTaskIdGet(taskId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<HomeworkTaskForEditingViewModel> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).apiTasksGetForEditingByTaskIdGet(taskId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksQuestionsByTaskIdGet(taskId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GetTaskQuestionDto>> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).apiTasksQuestionsByTaskIdGet(taskId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} taskId 
         * @param {CreateTaskViewModel} [taskViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksUpdateByTaskIdPut(taskId: number, taskViewModel?: CreateTaskViewModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TasksApiFetchParamCreator(configuration).apiTasksUpdateByTaskIdPut(taskId, taskViewModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {AddAnswerForQuestionDto} [answer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksAddAnswerPost(answer?: AddAnswerForQuestionDto, options?: any) {
            return TasksApiFp(configuration).apiTasksAddAnswerPost(answer, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} homeworkId 
         * @param {CreateTaskViewModel} [taskViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksAddByHomeworkIdPost(homeworkId: number, taskViewModel?: CreateTaskViewModel, options?: any) {
            return TasksApiFp(configuration).apiTasksAddByHomeworkIdPost(homeworkId, taskViewModel, options)(fetch, basePath);
        },
        /**
         * 
         * @param {AddTaskQuestionDto} [question] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksAddQuestionPost(question?: AddTaskQuestionDto, options?: any) {
            return TasksApiFp(configuration).apiTasksAddQuestionPost(question, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksDeleteByTaskIdDelete(taskId: number, options?: any) {
            return TasksApiFp(configuration).apiTasksDeleteByTaskIdDelete(taskId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksGetByTaskIdGet(taskId: number, options?: any) {
            return TasksApiFp(configuration).apiTasksGetByTaskIdGet(taskId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksGetForEditingByTaskIdGet(taskId: number, options?: any) {
            return TasksApiFp(configuration).apiTasksGetForEditingByTaskIdGet(taskId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksQuestionsByTaskIdGet(taskId: number, options?: any) {
            return TasksApiFp(configuration).apiTasksQuestionsByTaskIdGet(taskId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} taskId 
         * @param {CreateTaskViewModel} [taskViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTasksUpdateByTaskIdPut(taskId: number, taskViewModel?: CreateTaskViewModel, options?: any) {
            return TasksApiFp(configuration).apiTasksUpdateByTaskIdPut(taskId, taskViewModel, options)(fetch, basePath);
        },
    };
};

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
    /**
     * 
     * @param {AddAnswerForQuestionDto} [answer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public apiTasksAddAnswerPost(answer?: AddAnswerForQuestionDto, options?: any) {
        return TasksApiFp(this.configuration).apiTasksAddAnswerPost(answer, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} homeworkId 
     * @param {CreateTaskViewModel} [taskViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public apiTasksAddByHomeworkIdPost(homeworkId: number, taskViewModel?: CreateTaskViewModel, options?: any) {
        return TasksApiFp(this.configuration).apiTasksAddByHomeworkIdPost(homeworkId, taskViewModel, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {AddTaskQuestionDto} [question] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public apiTasksAddQuestionPost(question?: AddTaskQuestionDto, options?: any) {
        return TasksApiFp(this.configuration).apiTasksAddQuestionPost(question, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public apiTasksDeleteByTaskIdDelete(taskId: number, options?: any) {
        return TasksApiFp(this.configuration).apiTasksDeleteByTaskIdDelete(taskId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public apiTasksGetByTaskIdGet(taskId: number, options?: any) {
        return TasksApiFp(this.configuration).apiTasksGetByTaskIdGet(taskId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public apiTasksGetForEditingByTaskIdGet(taskId: number, options?: any) {
        return TasksApiFp(this.configuration).apiTasksGetForEditingByTaskIdGet(taskId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public apiTasksQuestionsByTaskIdGet(taskId: number, options?: any) {
        return TasksApiFp(this.configuration).apiTasksQuestionsByTaskIdGet(taskId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} taskId 
     * @param {CreateTaskViewModel} [taskViewModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public apiTasksUpdateByTaskIdPut(taskId: number, taskViewModel?: CreateTaskViewModel, options?: any) {
        return TasksApiFp(this.configuration).apiTasksUpdateByTaskIdPut(taskId, taskViewModel, options)(this.fetch, this.basePath);
    }

}

